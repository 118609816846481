import gql from "graphql-tag";

export const CancelRequestedBookingTicketMutation = gql`
    mutation CancelRequestedBookingTicket(
        $id: ID
        $cancelBookingTicket: Boolean
    ) {
        CancelRequestedBookingTicket(
            id: $id
            cancelBookingTicket: $cancelBookingTicket
        )
    }
`;
