<template lang="pug">
  .card
    .card-body(style="overflow: hidden;")
      .card-title
        .row
          .col-12
            .text-left
              h5 {{header}}

      .chart-container(style="position: relative; margin: auto; width: 100%; height: 100%;")
        LineChart(:items="items", :from="from", :to="to" display-unit="days" :title="this.$t('widgets.sales.last90days').toString()")

</template>

<script>
import EventBus from "../../..//views/event-bus";
import moment from "moment";
import LineChart from "@/views/components/charts/LineChart";
import { ItemsComponent } from "@/lib/components/items/ItemsComponent";
import { OrderStatusEnum } from "@/lib/enums/orderStatus/OrderStatusEnum.ts";

export default {
    name: "SalesWidget",
    components: { LineChart },
    props: {
        slug: String,
        header: String,
    },
    data() {
        return {
            hostId: this.$session.get("hostId"),
            items: [],
            from: moment().subtract(90, "days").toDate(),
            to: new Date(),

            moment: moment,
        };
    },
    async mounted() {
        try {
            EventBus.$on(
                "changeHostId",
                function (hostId) {
                    this.hostId = hostId;
                    this.loadItems();
                }.bind(this)
            );
            this.loadItems();
        } catch (e) {
            console.error(e);
            this.$alert(e.message);
        }
    },
    methods: {
        async loadItems() {
            try {
                const itemsComponent = new ItemsComponent();
                const items = await itemsComponent.getItems(
                    this.$apollo,
                    undefined,
                    this.hostId,
                    undefined,
                    undefined,
                    this.from,
                    this.to,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    [
                        OrderStatusEnum.Received,
                        OrderStatusEnum.Paid,
                        OrderStatusEnum.Completed,
                    ]
                );
                this.items = items;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
};
</script>

<style scoped></style>
