import { Vouchers } from "@/lib/graphql/vouchers/Vouchers.ts";

export class VouchersComponent {
    async getHostsVouchers(
        apollo,
        hostIds,
        filter,
        voucherStatusIds,
        from,
        to,
        offset,
        limit,
        sort
    ) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!hostIds) {
                return undefined;
            }
            const vouchers = await apollo
                .query({
                    query: Vouchers.Queries.HostsVouchers,
                    fetchPolicy: "no-cache",
                    variables: {
                        hostIds: hostIds,
                        filter: filter,
                        voucherStatus: voucherStatusIds,
                        from: from,
                        to: to,
                        offset: offset,
                        limit: limit,
                        sort: sort,
                    },
                })
                .then(({ data }) => data?.hostsVouchers)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!vouchers) {
                return undefined;
            }
            return vouchers;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getHostsVouchersCount(
        apollo,
        hostIds,
        filter,
        voucherStatusIds,
        from,
        to
    ) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!hostIds) {
                return undefined;
            }
            const vouchersCount = await apollo
                .query({
                    query: Vouchers.Queries.HostsVouchersCount,
                    fetchPolicy: "no-cache",
                    variables: {
                        hostIds: hostIds,
                        filter: filter,
                        voucherStatus: voucherStatusIds,
                        from: from,
                        to: to,
                    },
                })
                .then(({ data }) => data?.hostsVouchersCount)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (vouchersCount == undefined) {
                return undefined;
            }
            return vouchersCount;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getHostVouchersTable(
        apollo,
        hostIds,
        filter,
        voucherStatus,
        from,
        to,
        offset,
        limit,
        sort
    ) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!hostIds) {
                return undefined;
            }
            const hostsVouchersHtml = await apollo
                .query({
                    query: Vouchers.Queries.HostsVouchersHtml,
                    fetchPolicy: "no-fetch",
                    variables: {
                        hostIds: hostIds,
                        filter: filter,
                        voucherStatus: voucherStatus,
                        sort: sort,
                        from: from,
                        to: to,
                        offset: offset,
                        limit: limit,
                        includeTableHeader: offset == 0,
                    },
                })
                .then(({ data }) => data?.hostsVouchersHtml)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!hostsVouchersHtml) {
                return undefined;
            }
            return hostsVouchersHtml;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    // @ts-ignore
    createElementFromHTML(htmlString) {
        try {
            const div = document.createElement("div");
            div.innerHTML = htmlString.trim();
            // Change this to div.childNodes to support multiple top-level nodes.
            return div.firstChild;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }
}
