<template lang="pug">
tr(:class="{ 'text-danger': moment(voucher.ValidTill).isBefore(new Date()) }" :key="voucher.Id")
  th {{ voucher.Id }}
  td
    span(v-if="order") {{ order.PaidAt | formatDateTime }}
  td
    span(v-if="product") {{ product.Uebernachtungen }}
  td
    span(v-if="bookingRequest") {{ bookingRequest.LastName }}, {{ bookingRequest.FirstName }}
  td
    template(v-if="voucher.IsSelfSale")
      | touriDat-Gutschein Werbemaßnahme
    template(v-else)
      span(v-if="item") {{ item.SingleItemPriceGross - item.InsuranceAmount | formatEuro }}
  td
    label {{$t("vouchers.voucherStatus." + voucher.Status)}}
  td
    .form-group.custom-form-group.align-self-center(v-if="(!voucher.IsSelfSale && voucher.Status == VoucherStatusEnum.Created) || voucher.Status == VoucherStatusEnum.Cancelled")
      .input-group
        input.form-control.custom-control(type="text" :value="voucher.BookedAt | formatDate" :placeholder="$t('vouchers.tableColumn.arrivalOn')" disabled)
        .input-group-append
          button.button.button-primary.button-td-grey.button-icon.disabled
            font-awesome-icon(:icon="['fas','calendar']")
    .form-group.custom-form-group.align-self-center(v-else)
      .input-group(@click="showArrivalModal")
        input.form-control.custom-control.cursor-pointer(type="text" :value="voucher.BookedAt | formatDate" :placeholder="$t('vouchers.tableColumn.arrivalOn')")
        .input-group-append
          button.button.button-primary.button-td-grey.button-icon
            font-awesome-icon(:icon="['fas','calendar']")

  td
    .form-group.custom-form-group.align-self-center
      .input-group(@click="showNotesModal")
        input.form-control.custom-control.cursor-pointer(type="text" :value="voucher.BookingInfo" :placeholder="$t('vouchers.tableColumn.notes')")
        .input-group-append
          button.button.button-primary.button-td-grey.button-icon
            font-awesome-icon(:icon="['fas','pen']")

  td
    router-link.button.button-primary.button-tdays(:to="{ name: 'VoucherDetails', params: { voucherId: voucher.Id } }")
      |  {{$t("constants.buttons.show")}}

</template>

<script>
import VoucherArrivalModal from "../../../modals/VoucherArrivalModal.vue";
import VoucherStatusEnum from "../../../../lib/enums/voucherStatus/VoucherStatusEnum";
import VoucherBookingInfoModal from "@/views/modals/VoucherBookingInfoModal";
import { Items } from "@/lib/graphql/items/Items.ts";
import EventBus from "@/views/event-bus";
import { BookingRequests } from "@/lib/graphql/bookingRequests/BookingRequests.ts";
import { Products } from "@/lib/graphql/products/Products.ts";
import { Orders } from "@/lib/graphql/orders/Orders.ts";

const moment = require("moment");

export default {
    name: "VoucherTableColumn",
    components: { VoucherArrivalModal },
    props: {
        voucher: {
            type: Object,
            required: true,
        },
        isSmallTableColumn: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {
            item: undefined,
            order: undefined,
            product: undefined,
            bookingRequest: undefined,

            VoucherStatusEnum: VoucherStatusEnum,
            moment: moment,
        };
    },
    mounted() {
        try {
            this.loadBookingRequest();
            this.loadItem();
            this.loadProduct();
            this.loadOrder();
        } catch (e) {
            console.log(e);
            this.$alert(e.message);
        }
    },
    methods: {
        loadProduct() {
            try {
                this.$apollo
                    .query({
                        query: Products.Queries.Product,
                        variables: {
                            id: this.voucher.ProductId,
                        },
                    })
                    .then(({ data }) => {
                        if (!data || !data.product) {
                            return;
                        }
                        this.product = data.product;
                    })
                    .catch((e) => {
                        console.log(e);
                        this.$alert(e.message);
                    });
            } catch (e) {
                console.log(e);
                this.$alert(e.message);
            }
        },
        loadBookingRequest() {
            try {
                EventBus.$emit("changeLoadingState", true);
                this.$apollo
                    .query({
                        query: BookingRequests.Queries
                            .BookingRequestByVoucherId,
                        fetchPolicy: "no-cache",
                        variables: {
                            voucherId: this.voucher.Id,
                        },
                    })
                    .then(({ data }) => {
                        EventBus.$emit("changeLoadingState", false);
                        if (!data || !data.bookingRequestByVoucherId) {
                            return;
                        }
                        this.bookingRequest = data.bookingRequestByVoucherId;
                    })
                    .catch((e) => {
                        EventBus.$emit("changeLoadingState", true);
                        console.log(e);
                        this.$alert(e.message);
                    });
            } catch (e) {
                console.log(e);
                this.$alert(e.message);
            }
        },
        loadItem() {
            try {
                this.$apollo
                    .query({
                        query: Items.Queries.ItemByVoucherId,
                        variables: {
                            voucherId: this.voucher.Id,
                        },
                    })
                    .then(({ data }) => {
                        if (!data || !data.itemByVoucherId) {
                            return;
                        }
                        this.item = data.itemByVoucherId;
                    })
                    .catch((e) => {
                        console.log(e);
                        this.$alert(e.message);
                    });
            } catch (e) {
                console.log(e);
                this.$alert(e.message);
            }
        },
        loadOrder() {
            try {
                this.$apollo
                    .query({
                        query: Orders.Queries.OrderByVoucherId,
                        variables: {
                            voucherId: this.voucher.Id,
                        },
                    })
                    .then(({ data }) => {
                        if (!data || !data.orderByVoucherId) {
                            return;
                        }
                        this.order = data.orderByVoucherId;
                    })
                    .catch((e) => {
                        console.log(e);
                        this.$alert(e.message);
                    });
            } catch (e) {
                console.log(e);
                this.$alert(e.message);
            }
        },
        showArrivalModal() {
            this.$modal.show(
                VoucherArrivalModal,
                {
                    voucherId: this.voucher.Id,
                },
                {
                    resizable: true,
                    height: "auto",
                    width: "60%",
                }
            );
        },
        showNotesModal() {
            this.$modal.show(
                VoucherBookingInfoModal,
                {
                    voucher: this.voucher,
                },
                {
                    resizable: true,
                    height: "auto",
                }
            );
        },
    },
};
</script>
