import { CancelRequestedBookingTicketMutation } from "../../graphql/mutations/cancelRequestedBookingTicket/CancelRequestedBookingTicketMutation";
import { BookingTickets } from "@/lib/graphql/bookingTickets/BookingTickets.ts";

export class CancelBookingTicketComponent {
    async cancelRequestedBookingTicket(
        apolloClient,
        bookingTicketId,
        cancelBookingTicket,
        lang = "de",
        callback
    ) {
        try {
            if (!apolloClient) {
                callback({
                    success: false,
                    message: lang == "en" ? "" : "",
                });
                return;
            }
            if (!bookingTicketId) {
                callback({
                    success: false,
                    message: lang == "en" ? "" : "",
                });
                return;
            }
            apolloClient
                .mutate({
                    mutation: CancelRequestedBookingTicketMutation,
                    variables: {
                        id: bookingTicketId,
                        cancelBookingTicket: cancelBookingTicket,
                    },
                })
                .then((response) => {
                    if (
                        !response ||
                        !response.data ||
                        !response.data.CancelRequestedBookingTicket
                    ) {
                        callback({
                            success: false,
                            message:
                                lang == "en"
                                    ? "Booking ticket could not be canceled!"
                                    : "Das Buchungsticket konnte nicht storniert werden!",
                        });
                        return;
                    }
                    callback({
                        success: true,
                    });
                })
                .catch((error) => {
                    console.log(error);
                    callback({
                        success: false,
                        message: error.message,
                    });
                });
        } catch (e) {
            console.log(e);
            callback({
                success: false,
                message: e.message,
            });
        }
    }

    async cancelBookingTicket(
        apolloClient,
        bookingTicketId,
        userId,
        lang = "de",
        callback
    ) {
        try {
            if (!apolloClient) {
                callback({
                    IsSuccess: false,
                    Message:
                        lang == "en"
                            ? "no apollo client found"
                            : "Kein Apollo-Client gefunden",
                });
                return;
            }
            if (!bookingTicketId) {
                callback({
                    IsSuccess: false,
                    Message:
                        lang == "en"
                            ? "no booking ticket id found"
                            : "Keine Buchungsticket-Id gefunden",
                });
                return;
            }
            if (!userId) {
                callback({
                    IsSuccess: false,
                    Message:
                        lang == "en"
                            ? "no user id found"
                            : "Keine Benutzer-Id gefunden",
                });
                return;
            }
            const cancelledBookingTicket = await apolloClient
                .mutate({
                    mutation: BookingTickets.Mutations.CancelBookingTicket,
                    fetchPolicy: "no-cache",
                    variables: {
                        id: bookingTicketId,
                        userId: userId,
                    },
                })
                .then(({ data }) => data?.data?.cancelBookingTicket)
                .catch((e) => {
                    console.error(e);
                    return {
                        IsSuccess: false,
                        Message:
                            lang == "en"
                                ? "Booking ticket could not be canceled!"
                                : "Das Buchungsticket konnte nicht storniert werden!",
                    };
                });
            if (!cancelledBookingTicket) {
                return callback({
                    IsSuccess: false,
                    Message:
                        lang == "en"
                            ? "Booking ticket could not be canceled!"
                            : "Das Buchungsticket konnte nicht storniert werden!",
                });
            }
            return callback(cancelledBookingTicket);
        } catch (e) {
            console.log(e);
            callback({
                IsSuccess: false,
                Message: e.message,
            });
        }
    }
}
