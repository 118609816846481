import gql from "graphql-tag";

export const GetHostQuery = gql`
    query GetHost($id: ID!) {
        host(id: $id) {
            Id
            ProvisionFP
            Verkaufsgebuehren
            Match1
            PPLZ
            PORT
            PLAND
            RLAND
            PAdresse3
            PAdresse4
            RAdresse1
            RAdresse2
            RAdresse3
            RAdresse4
            RAdresse5
            RAdresse6
            RAdresse7
            RPLZ
            RORT
            Ansprechpartner
            TELEFON1
            TELEFON2
            TELEFAX1
            EMAIL1
            EMAIL2
            Internetadresse1
            Internetadresse2
            IBAN
            AccountOwner
        }
    }
`;
