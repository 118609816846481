<template lang="pug">
.container-fluid.p-4
  .row(v-if="voucher")
    .col-6
      .modal-headline {{$t("modals.voucherArrivalModal.modalHeadline")}}
    .col-6
      .float-right
        font-awesome-icon(:icon="['fas', 'times']" @click="$emit('close')" style="cursor: pointer;")
    .col-12
      hr
    .col-6
      .row
        .col-12.col-lg-6
          .form-group
            label(for="Email").form-label {{$t("vouchers.detail.guestMail")}}
            input#Email.form-control.custom-control(type="text", v-model="bookingRequest.Email")
        .col-12.col-lg-6
          .form-group
            label.form-label.w-100 {{$t("vouchers.detail.formOfAddress")}}
            select.custom-select(v-model="bookingRequest.Title")
              option {{$t("constants.gender.m")}}
              option {{$t("constants.gender.f")}}
        .col-12.col-lg-6
          .form-group
            label(for="Forename").form-label {{$t("vouchers.detail.firstName")}}
            input#Forename.form-control.custom-control(type="text", v-model="bookingRequest.FirstName")
        .col-12.col-lg-6
          .form-group
            label(for="Surname").form-label {{$t("vouchers.detail.lastName")}}
            input#Surname.form-control.custom-control(type="text", v-model="bookingRequest.LastName")
        .col-12.col-lg-4
          .form-group
            label(for="StreetAndNumber").form-label {{$t("vouchers.detail.streetAndNumber")}}
            input#StreetAndNumber.form-control.custom-control(type="text", v-model="bookingRequest.Street")
        .col-12.col-lg-4
          .form-group
            label(for="Postcode").form-label {{$t("vouchers.detail.zipCode")}}
            input#Postcode.form-control.custom-control(type="text", v-model="bookingRequest.ZipCode")
        .col-12.col-lg-4
          .form-group
            label(for="City").form-label {{$t("vouchers.detail.city")}}
            input#City.form-control.custom-control(type="text", v-model="bookingRequest.City")

        .col-12.col-lg-6
          .form-group
            label.form-label.w-100 {{$t("vouchers.detail.country")}}
            select.custom-select(v-model="bookingRequest.Country")
              option(value="DE") {{$t("constants.countries.de")}}
              option(value="BE") {{$t("constants.countries.be")}}
              option(value="FR") {{$t("constants.countries.fr")}}
              option(value="IT") {{$t("constants.countries.it")}}
              option(value="LU") {{$t("constants.countries.lu")}}
              option(value="NL") {{$t("constants.countries.nl")}}
              option(value="AT") {{$t("constants.countries.at")}}
              option(value="PL") {{$t("constants.countries.pl")}}
              option(value="CH") {{$t("constants.countries.ch")}}
        .col-12.col-lg-6
          .form-group
            label(for="Phone").form-label {{$t("vouchers.detail.phone")}}
            input#Phone.form-control.custom-control(type="text", v-model="bookingRequest.Phone")

    .col-6
      .row
        .col-12
          Datepicker(
            @input="bookingDate = $event",
            :inline="true",
            :language="de",
            :monday-first="true",
          )

  .row
    .col-6.mt-2
      button.button.button-primary.button-tdays.w-100.float-md-right(@click="takeDeliveryData")
        | {{$t("constants.buttons.takeDeliveryAddressData")}}
    .col-6.mt-2
      button.button.button-primary.button-tdays.w-100.float-md-right(@click="saveBookingRequest")
        | {{$t("constants.buttons.saveChanges")}}

</template>

<script>
import { de, en } from "vuejs-datepicker/dist/locale";
import { EmailValidatorComponent } from "../../lib/components/emailValidator/EmailValidatorComponent";
import { CancelBookingTicketComponent } from "../../lib/components/cancelBookingTicket/CancelBookingTicketComponent";
import { BookingTicketStatusEnum } from "../../lib/enums/bookingTicketStatus/BookingTicketStatusEnum";
import Datepicker from "vuejs-datepicker";
import EventBus from "../event-bus";
import VoucherStatusEnum from "../../lib/enums/voucherStatus/VoucherStatusEnum";
import { Vouchers } from "@/lib/graphql/vouchers/Vouchers.ts";
import { BookingRequests } from "@/lib/graphql/bookingRequests/BookingRequests.ts";
import { Addresses } from "@/lib/graphql/addresses/Addresses.ts";
import BookingDataComponent from "@/lib/components/bookingData/BookingDataComponent";

const moment = require("moment");

export default {
    name: "VoucherArrivalModal",
    props: {
        voucherId: String,
    },
    components: {
        Datepicker,
    },
    data() {
        return {
            voucher: undefined,
            bookingRequest: {
                VoucherId: this.voucherId,
                Country: "DE",
                Title: "Herr",
                CreatedBy: this.$session.get("userId"),
            },
            deliveryAddress: undefined,
            invoiceAddress: undefined,
            bookingDate: undefined,
            de: this.$session.get("lang") == "en" ? en : de,
            BookingTicketStatusEnum: BookingTicketStatusEnum,
        };
    },
    async mounted() {
        try {
            await this.loadVoucher();
        } catch (e) {
            console.log(e);
            this.$alert(e.message);
        }
    },
    methods: {
        async loadVoucher() {
            try {
                EventBus.$emit("changeLoadingState", true);
                const voucher = await this.$apollo
                    .query({
                        query: Vouchers.Queries.Voucher,
                        fetchPolicy: "no-cache",
                        variables: {
                            id: this.voucherId,
                        },
                    })
                    .then(({ data }) => data?.voucher)
                    .catch((e) => {
                        console.log(e);
                        return undefined;
                    });
                EventBus.$emit("changeLoadingState", false);
                if (!voucher) {
                    return this.$alert(
                        "Es konnte kein Gutschein geladen werden."
                    );
                }
                this.voucher = voucher;
                this.bookingDate = voucher.BookedAt;
                await this.loadBookingRequest();
                await this.loadDeliveryAddress();
                await this.loadInvoiceAddress();
            } catch (e) {
                console.log(e);
                this.$alert(e.message);
            }
        },
        async loadBookingRequest() {
            try {
                const bookingRequest = await this.$apollo
                    .query({
                        query: BookingRequests.Queries
                            .BookingRequestByVoucherId,
                        fetchPolicy: "no-cache",
                        variables: {
                            voucherId: this.voucherId,
                        },
                    })
                    .then(({ data }) => data?.bookingRequestByVoucherId)
                    .catch((e) => {
                        console.log(e);
                        return undefined;
                    });
                if (!bookingRequest) {
                    return;
                }
                this.bookingRequest = bookingRequest;
            } catch (e) {
                console.log(e);
                this.$alert(e.message);
            }
        },
        async loadDeliveryAddress() {
            try {
                const deliveryAddress = await this.$apollo
                    .query({
                        query: Addresses.Queries.DeliveryAddressByVoucherId,
                        fetchPolicy: "no-cache",
                        variables: {
                            voucherId: this.voucherId,
                        },
                    })
                    .then(({ data }) => data?.deliveryAddressByVoucherId)
                    .catch((e) => {
                        console.log(e);
                        return undefined;
                    });
                if (!deliveryAddress) {
                    return;
                }
                this.deliveryAddress = deliveryAddress;
            } catch (e) {
                console.log(e);
                this.$alert(e.message);
            }
        },
        async loadInvoiceAddress() {
            try {
                const invoiceAddress = await this.$apollo
                    .query({
                        query: Addresses.Queries.InvoiceAddressByVoucherId,
                        fetchPolicy: "no-cache",
                        variables: {
                            voucherId: this.voucherId,
                        },
                    })
                    .then(({ data }) => data?.invoiceAddressByVoucherId)
                    .catch((e) => {
                        console.log(e);
                        return undefined;
                    });
                if (!invoiceAddress) {
                    return;
                }
                this.invoiceAddress = invoiceAddress;
            } catch (e) {
                console.log(e);
                this.$alert(e.message);
            }
        },
        async cancelBookingTicket() {
            try {
                this.$prompt(
                    this.$t("constants.messages.enterCode").toString()
                ).then((code) => {
                    if (
                        this.voucher.Guid.slice(-6).toLowerCase() !=
                        code.toLowerCase()
                    ) {
                        return this.$alert(
                            this.$t(
                                "constants.messages.noCodeMatch"
                            ).toString(),
                            this.$t("constants.messages.alertTitle").toString()
                        );
                    }
                    const bookingTicket = this.bookingTickets.find(
                        (t) =>
                            t.BookingTicketStatusId ==
                            BookingTicketStatusEnum.DatesCancelRequested
                    );
                    if (!bookingTicket) {
                        return;
                    }
                    const cancelBookingTicketComponent =
                        new CancelBookingTicketComponent();
                    cancelBookingTicketComponent.cancelBookingTicket(
                        this.$apollo,
                        bookingTicket.Id,
                        this.$session.get("userId"),
                        this.$session.get("lang"),
                        (response) => {
                            if (!response) {
                                return;
                            }
                            if (response.Message) {
                                this.$alert(
                                    response.Message,
                                    this.$t(
                                        "constants.messages.successTitle"
                                    ).toString()
                                );
                            }
                            if (response.success) {
                                this.loadVoucher();
                                this.loadBookingRequest();
                            }
                        }
                    );
                });
            } catch (e) {
                this.$alert(
                    e.message,
                    this.$t("constants.messages.alertTitle").toString()
                );
            }
        },
        async takeDeliveryData() {
            try {
                if (!this.deliveryAddress) {
                    return this.$alert(
                        "Es steht keine Lieferadresse für die Datenübernahme zur Verfügung.",
                        this.$t("constants.messages.alertTitle").toString()
                    );
                }
                const bookingRequest = this.bookingRequest;
                this.bookingRequest = undefined;
                if (this.deliveryAddress.Email) {
                    bookingRequest.Email = this.deliveryAddress.Email;
                } else if (this.invoiceAddress && this.invoiceAddress.Email) {
                    bookingRequest.Email = this.invoiceAddress.Email;
                }
                bookingRequest.Title = this.deliveryAddress.FormOfAddress;
                bookingRequest.FirstName = this.deliveryAddress.FirstName;
                bookingRequest.LastName = this.deliveryAddress.LastName;
                bookingRequest.Street = this.deliveryAddress.Street;
                bookingRequest.ZipCode = this.deliveryAddress.PostalCode;
                bookingRequest.City = this.deliveryAddress.City;
                bookingRequest.Phone = this.deliveryAddress.Phone;
                this.bookingRequest = bookingRequest;
            } catch (e) {
                console.log(e);
                this.$alert(
                    e.message,
                    this.$t("constants.messages.alertTitle").toString()
                );
            }
        },
        async saveBookingRequest() {
            try {
                if (!this.bookingRequest.Email) {
                    return this.$alert(
                        "Bitte füllen Sie die E-Mail Adresse!",
                        this.$t("constants.messages.alertTitle").toString()
                    );
                }
                const emailValidatorComponent = new EmailValidatorComponent();
                const validationResult = emailValidatorComponent.validateEmail(
                    this.bookingRequest.Email
                );
                if (!validationResult) {
                    return this.$alert(
                        "Die E-Mail Adresse ist nicht gültig!",
                        this.$t("constants.messages.alertTitle").toString()
                    );
                }
                if (!this.bookingRequest.Title) {
                    return this.$alert(
                        "Bitte füllen Sie die Anrede!",
                        this.$t("constants.messages.alertTitle").toString()
                    );
                }
                if (!this.bookingRequest.FirstName) {
                    return this.$alert(
                        "Bitte füllen Sie den Vornamen!",
                        this.$t("constants.messages.alertTitle").toString()
                    );
                }
                if (!this.bookingRequest.LastName) {
                    return this.$alert(
                        "Bitte füllen Sie den Nachnamen!",
                        this.$t("constants.messages.alertTitle").toString()
                    );
                }
                if (!this.isValidDate(this.bookingDate)) {
                    return this.$alert(
                        "Bitte füllen Sie das Anreisedatum korrekt!",
                        this.$t("constants.messages.alertTitle").toString()
                    );
                }

                if (
                    this.voucher.BookingInfo &&
                    !this.voucher.BookingInfoEditedBy
                ) {
                    return this.$alert(
                        "Falls Sie eine Notiz hinterlegen möchten, bitte geben Sie ebenfalls die bearbeitende Person an.",
                        this.$t("constants.messages.alertTitle").toString()
                    );
                }
                if (
                    !this.voucher.BookingInfo &&
                    this.voucher.BookingInfoEditedBy
                ) {
                    return this.$alert(
                        "Falls Sie eine bearbeitende Person angeben, müssen Sie auch eine Notiz hinterlegen.",
                        this.$t("constants.messages.alertTitle").toString()
                    );
                }
                if (this.voucher.Status == VoucherStatusEnum.Cancelled) {
                    return this.$alert(
                        "Der Gutschein ist storniert!",
                        this.$t("constants.messages.alertTitle").toString()
                    );
                }

                const blockedHostIds = [31022];
                const isBlocked = blockedHostIds.includes(
                    this.voucher.ProductId
                );
                const dateChanged =
                    this.voucher.BookedAt != undefined &&
                    !moment(this.voucher.BookedAt).isSame(
                        this.bookingDate,
                        "day"
                    );
                if (isBlocked || dateChanged) {
                    this.$prompt(
                        this.$t("constants.messages.enterCode").toString()
                    )
                        .then(async (code) => {
                            if (
                                this.voucher.Guid.slice(-6).toLowerCase() !=
                                code.toLowerCase()
                            ) {
                                return this.$alert(
                                    this.$t(
                                        "constants.messages.noCodeMatch"
                                    ).toString(),
                                    this.$t(
                                        "constants.messages.alertTitle"
                                    ).toString()
                                );
                            }
                            await this._saveBookingRequest();
                        })
                        .catch(() => {
                            return;
                        });
                } else {
                    this.$confirm(
                        this.$t("constants.messages.saveChanges").toString(),
                        this.$t("constants.messages.questionTitle").toString()
                    ).then(async () => {
                        await this._saveBookingRequest();
                    });
                }
            } catch (e) {
                console.log(e);
                this.$alert(e.message);
            }
        },
        async _saveBookingRequest() {
            try {
                if (!this.bookingRequest.Id) {
                    this.bookingRequest.CreatedBy = this.$session.get("userId");
                } else {
                    this.bookingRequest.UpdatedBy = this.$session.get("userId");
                }
                EventBus.$emit("changeLoadingState", true);
                const bookingDataComponent = new BookingDataComponent();
                const savedBookingData =
                    await bookingDataComponent.saveBookingData(
                        this.$apollo,
                        this.bookingRequest,
                        this.bookingDate,
                        this.$session.get("userId")
                    );
                EventBus.$emit("changeLoadingState", false);
                if (!savedBookingData) {
                    return this.$alert(
                        "Die Buchungsdaten konnten nicht gespeichert werden."
                    );
                }
                if (savedBookingData.Message) {
                    this.$alert(savedBookingData.Message);
                }
                if (savedBookingData.IsSuccess) {
                    EventBus.$emit("updateVouchersList");
                    await this.loadVoucher();
                }
            } catch (e) {
                console.log(e);
                this.$alert(e.message);
            }
        },
        isValidDate(date, checkFutureDates) {
            try {
                if (!date) {
                    return false;
                }
                if (date.toString() == "Invalid Date") {
                    return false;
                }
                if (date instanceof Date && isNaN(date)) {
                    return false;
                }
                let isValid = moment(date).isValid();
                if (!isValid) {
                    return false;
                }
                const _date = new Date(date);
                if (Object.prototype.toString.call(_date) === "[object Date]") {
                    if (isNaN(_date.getTime())) {
                        return false;
                    }
                } else {
                    return false;
                }
                if (moment(date).year() < 1900) {
                    return false;
                }
                if (moment(date).year() > moment().add(5, "years").year()) {
                    return false;
                }
                if (!checkFutureDates) {
                    return true;
                }
                if (moment(date).year() > moment().year()) {
                    return false;
                }
                return true;
            } catch (e) {
                console.error(e);
                return false;
            }
        },
    },
};
</script>
