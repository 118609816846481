import gql from "graphql-tag";

export const Users = {
    Queries: {
        UserByEmail: gql`
        query userByEmail($email: String) {
            userByEmail(email: $email) {
                Id
            }
        }`,
        User: gql`
        query user($id: ID, $isDashboardUser: Boolean, $isProductManagerUser: Boolean) {
            user(id: $id, isDashboardUser: $isDashboardUser, isProductManagerUser: $isProductManagerUser) {
                Id
                Email
                FirstName
                LastName
            }
        }`,
        UserByGuid: gql`
        query userByGuid($guid: String) {
            userByGuid(guid: $guid) {
                Id
                Email
                FirstName
                LastName
                Password
            }
        }`,
        Users: gql`
        query users($hostPoolIds: [ID], $offset: Int, $limit: Int) {
            users(hostPoolIds: $hostPoolIds, offset: $offset, limit: $limit) {
                Id
                Email
                FirstName
                LastName
            }
        }`,
        UsersByHostPoolUserId: gql`
        query usersByHostPoolUserId($userId: ID, $offset: Int, $limit: Int) {
            usersByHostPoolUserId(userId: $userId, offset: $offset, limit: $limit) {
                Id
                Email
                FirstName
                LastName
            }
        }`,
        UsersCountByHostPoolUserId: gql`
        query usersCountByHostPoolUserId($userId: ID) {
            usersCountByHostPoolUserId(userId: $userId)
        }`,
        UsersCount: gql`
        query usersCount($hostPoolIds: [ID]!) {
            usersCount(hostPoolIds: $hostPoolIds)
        }`,
    },
    Mutations: {
        SaveUser: gql`
        mutation saveUser($user: UsersInput, $hostIds: [ID], $permissionIds: [ID], $isDashboardUser: Boolean) {
            saveUser(user: $user, hostIds: $hostIds, permissionIds: $permissionIds, isDashboardUser: $isDashboardUser) {
                IsSuccess
                Message
            }
        }`,
        DeleteUser: gql`
        mutation DeleteUser($userId: ID) {
            DeleteUser(userId: $userId)
        }`,
        UpdateUserPassword: gql`
        mutation updateUserPassword($userId: ID, $password: String) {
            updateUserPassword(userId: $userId, password: $password) {
                IsSuccess
                Message
            }
        }`,
        SendResetUserPasswordEmail: gql`
        mutation sendResetUserPasswordEmail($email: String) {
            sendResetUserPasswordEmail(email: $email) {
                IsSuccess
                Message
            }
        }`,
    },
};
