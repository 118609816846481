<template lang="pug">
.container-fluid
  .row
    .col-12.mt-2
      h3 Benutzer
    .col
      .form-group
        label(for="Email").form-label {{$t("account.users.email")}}
        input#Email.form-control.custom-control(type="text", v-model="user.Email")
        small.form-text.text-muted.font-italic(v-if="user.Email && user.Email.length > 0 && !validateEmail(user.Email)")
          | {{$t("account.users.validEmail")}}
    .col
      .form-group
        label(for="FirstName").form-label {{$t("account.users.firstName")}}
        input#FirstName.form-control.custom-control(type="text", v-model="user.FirstName")
    .col
      .form-group
        label(for="LastName").form-label {{$t("account.users.lastName")}}
        input#LastName.form-control.custom-control(type="text", v-model="user.LastName")
    .col.mt-4(v-if="user.Id")
      .form-group
        button.button.button-primary.button-tdays.w-100(@click="resetPassword")
          | {{$t("reset-password.button.sendMail")}}

    .col-12
      .form-group
        label.form-label.w-100 {{$t("account.users.host")}}
        multiselect(
          :options="availableHosts"
          select-label="Zum Auswählen klicken",
          selected-label="Ausgewählt",
          deselect-label="Zum Entfernen klicken",
          v-model="assignedHosts",
          :searchable="true",
          :multiple="true",
          :close-on-select="false",
          :show-labels="true"
          label="Match1",
          track-by="Id",
          placeholder="Bitte ordnen Sie Gastgeber zu."
        )

    .col-12
      .form-group
        label.form-label.w-100 {{$t("account.users.userRights")}}
        multiselect(
          :options="permissions"
          select-label="Zum Auswählen klicken",
          selected-label="Ausgewählt",
          deselect-label="Zum Entfernen klicken",
          v-model="userPermissions",
          :searchable="true",
          :multiple="true",
          :close-on-select="false",
          :show-labels="true"
          label="Description",
          track-by="Id",
          :disabled="this.userId == $session.get('userId')"
          placeholder="Bitte ordnen Sie Rechte zu."
        )

    .col-12.mt-5
      button.button.button-primary.button-tdays.float-right(@click="saveUser")
        | {{$t("constants.buttons.save")}}

</template>

<script>
import { EmailValidatorComponent } from "../../../../lib/components/emailValidator/EmailValidatorComponent";
import { PermissionTypesEnum } from "../../../../lib/enums/permissionTypes/PermissionTypesEnum";
import EventBus from "../../../../views/event-bus";
import { Users } from "../../../../lib/graphql/users/Users.ts";
import { Hosts } from "../../../../lib/graphql/hosts/Hosts.ts";
import { Permissions } from "../../../../lib/graphql/permissions/Permissions.ts";

export default {
    name: "UserDetails",
    props: {
        userId: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            user: {
                IsDashboardUser: true,
            },
            availableHosts: [],
            permissions: [],

            assignedHosts: [],
            userPermissions: [],
        };
    },
    async mounted() {
        try {
            await this.loadUser();
            await this.loadPermissions();
            await this.loadAvailableHosts();
        } catch (e) {
            console.log(e);
            this.$alert(e.message);
        }
    },
    methods: {
        validateEmail(email) {
            const re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },
        async resetPassword() {
            try {
                if (this.user.Email.length == 0) {
                    this.$alert(
                        this.$t("login.errorMessages.e7").toString(),
                        this.$t("constants.messages.errorTitle").toString()
                    );
                    return;
                }
                const emailValidatorComponent = new EmailValidatorComponent();
                if (!emailValidatorComponent.validateEmail(this.user.Email)) {
                    this.$alert(
                        this.$t("login.errorMessages.e8").toString(),
                        this.$t("constants.messages.errorTitle").toString()
                    );
                    return;
                }
                EventBus.$emit("changeLoadingState", true);
                const sentResetUserPasswordEmail = await this.$apollo
                    .mutate({
                        mutation: Users.Mutations.SendResetUserPasswordEmail,
                        fetchPolicy: "no-cache",
                        variables: {
                            email: this.user.Email,
                        },
                    })
                    .then(({ data }) => data?.sendResetUserPasswordEmail)
                    .catch((e) => {
                        console.error(e);
                        return {
                            IsSuccess: false,
                            Message: e.message,
                        };
                    });
                EventBus.$emit("changeLoadingState", false);
                if (!sentResetUserPasswordEmail) {
                    return this.$alert(
                        "Die Email konnte nicht gesendet werden!",
                        "Achtung!"
                    );
                }
                if (!sentResetUserPasswordEmail.IsSuccess) {
                    return this.$alert(
                        sentResetUserPasswordEmail.Message,
                        "Achtung!"
                    );
                }
                this.$alert(
                    this.$t("reset-password.emailGotSent").toString(),
                    this.$t("constants.messages.successTitle").toString()
                );
            } catch (e) {
                console.log(e);
                this.$alert(
                    e.message,
                    this.$t("constants.messages.errorTitle").toString()
                );
            }
        },
        _validate() {
            if (this.user.Email.length == 0) {
                this.$alert(
                    this.$t("constants.messages.mailNotFilled").toString(),
                    this.$t("constants.messages.alertTitle").toString()
                );
                return false;
            }
            if (!this.validateEmail(this.user.Email)) {
                this.$alert(
                    this.$t(
                        "Die E-Mail Adresse hat nicht das richtige Format!"
                    ).toString(),
                    this.$t("constants.messages.alertTitle").toString()
                );
                return false;
            }
            if (this.user.FirstName.length == 0) {
                this.$alert(
                    this.$t("constants.messages.firstNameNotFilled").toString(),
                    this.$t("constants.messages.alertTitle").toString()
                );
                return false;
            }
            if (this.user.LastName.length == 0) {
                this.$alert(
                    this.$t("constants.messages.lastNameNotFilled").toString(),
                    this.$t("constants.messages.alertTitle").toString()
                );
                return false;
            }
            if (this.assignedHosts.length == 0) {
                this.$alert(
                    this.$t("constants.messages.assignHost").toString(),
                    this.$t("constants.messages.alertTitle").toString()
                );
                return false;
            }
            if (this.userPermissions.length == 0) {
                this.$alert(
                    this.$t("constants.messages.assignUserRights").toString(),
                    this.$t("constants.messages.alertTitle").toString()
                );
                return false;
            }
            return true;
        },
        async loadUser() {
            try {
                const user = await this.$apollo
                    .query({
                        query: Users.Queries.User,
                        variables: {
                            id: this.userId,
                            isDashboardUser: true,
                        },
                    })
                    .then(({ data }) => data?.user);
                if (!user) {
                    return;
                }
                this.user = user;
                await this.loadUserPermissions();
                await this.loadAssignedHosts();
            } catch (e) {
                console.log(e);
                this.$alert(e.message);
            }
        },
        async loadPermissions() {
            try {
                const permissions = await this.$apollo
                    .query({
                        query: Permissions.Queries.Permissions,
                        variables: {
                            permissionTypeId: PermissionTypesEnum.Dashboard,
                        },
                    })
                    .then(({ data }) => data?.permissions);
                if (!permissions) {
                    return;
                }
                this.permissions = permissions;
            } catch (e) {
                console.log(e);
                this.$alert(e.message);
            }
        },
        async loadUserPermissions() {
            try {
                const userPermissions = await this.$apollo
                    .query({
                        query: Permissions.Queries.UserPermissions,
                        variables: {
                            userId: this.user.Id,
                            permissionTypeId: PermissionTypesEnum.Dashboard,
                        },
                    })
                    .then(({ data }) => data?.userPermissions);
                if (!userPermissions) {
                    return;
                }
                this.userPermissions = userPermissions;
            } catch (e) {
                console.log(e);
                this.$alert(e.message);
            }
        },
        async loadAvailableHosts() {
            try {
                const availableHosts = await this.$apollo
                    .query({
                        query: Hosts.Queries.AvailableHosts,
                        variables: {
                            userId: this.$session.get("userId"),
                        },
                    })
                    .then(({ data }) => data?.availableHosts);
                if (!availableHosts) {
                    return undefined;
                }
                this.availableHosts = availableHosts;
            } catch (e) {
                console.log(e);
                this.$alert(e.message);
            }
        },
        async loadAssignedHosts() {
            try {
                const assignedHosts = await this.$apollo
                    .query({
                        query: Hosts.Queries.AssignedHosts,
                        variables: {
                            userId: this.user.Id,
                        },
                    })
                    .then(({ data }) => data?.assignedHosts);
                if (!assignedHosts) {
                    return;
                }
                this.assignedHosts = assignedHosts;
            } catch (e) {
                console.log(e);
                this.$alert(e.message);
            }
        },
        async saveUser() {
            try {
                const validated = this._validate();
                if (!validated) {
                    return;
                }
                const hostIds = [];
                this.assignedHosts.forEach((h) => hostIds.push(h.Id));
                const permissionIds = [];
                this.userPermissions.forEach((p) => permissionIds.push(p.Id));
                if (this.password && this.password.length > 0) {
                    this.user.Password = this.password;
                }

                if (!this.user.Id) {
                    this.user.CreatedBy = this.$session.get("userId");
                } else {
                    this.user.UpdatedBy = this.$session.get("userId");
                }
                const savedUser = await this.$apollo
                    .mutate({
                        mutation: Users.Mutations.SaveUser,
                        variables: {
                            user: this.user,
                            hostIds: hostIds,
                            permissionIds: permissionIds,
                            isDashboardUser: true,
                        },
                    })
                    .then(({ data }) => data?.saveUser);
                EventBus.$emit("changeLoadingState", false);
                if (!savedUser) {
                    this.$alert(
                        this.$t("constants.messages.userNotSaved").toString(),
                        this.$t("constants.messages.alertTitle").toString()
                    );
                }
                this.$alert(
                    this.$t(
                        "account.users.saveUserSuccess",
                        this.user.email
                    ).toString(),
                    this.$t("constants.messages.successTitle").toString()
                );
                if (savedUser.IsSuccess) {
                    EventBus.$emit("updateUsersList");
                    return this.$router.push({ name: "UsersList" });
                }
            } catch (e) {
                console.log(e);
                EventBus.$emit("changeLoadingState", false);
                this.$alert(
                    e.message,
                    this.$t("constants.messages.alertTitle").toString()
                );
            }
        },
    },
};
</script>
