import { Hosts } from "../../graphql/hosts/Hosts.ts";

export class HostsComponent {
    async saveHost(apollo, host, userId) {
        try {
            if (!apollo) {
                return {
                    IsSuccess: false,
                    Message: "Kein Apollo-Client gefunden.",
                };
            }
            if (!host) {
                return {
                    IsSuccess: false,
                    Message: "Keinen Gastgeber gefunden.",
                };
            }
            if (!userId) {
                return {
                    IsSuccess: false,
                    Message: "Keine Benutzer-Id gefunden.",
                };
            }
            const savedHost = await apollo
                .mutate({
                    mutation: Hosts.Mutations.SaveHost,
                    fetchPolicy: "no-cache",
                    variables: {
                        host: host,
                        userId: userId,
                    },
                })
                .then(({ data }) => data?.saveHost)
                .catch((e) => {
                    console.error(e);
                    return {
                        IsSuccess: false,
                        Message: e.message,
                    };
                });
            if (!savedHost) {
                return {
                    IsSuccess: false,
                    Message: "Der Gastgeber konnte nicht gespeichert werden.",
                };
            }
            return savedHost;
        } catch (e) {
            console.error(e);
            return {
                IsSuccess: false,
                Message: e.message,
            };
        }
    }

    async getAvailableHosts(apollo, userId) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!userId) {
                return undefined;
            }
            const availableHosts = await apollo
                .query({
                    query: Hosts.Queries.AvailableHosts,
                    variables: {
                        userId: userId,
                    },
                })
                .then(({ data }) => data?.availableHosts)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!availableHosts) {
                return undefined;
            }
            return availableHosts;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }
}
