const moment = require("moment");
const {
    BookingTickets,
} = require("@/lib/graphql/bookingTickets/BookingTickets.ts");
const {
    BookingTicketStatusEnum,
} = require("@/lib/enums/bookingTicketStatus/BookingTicketStatusEnum");
const {
    BookingDateRequests,
} = require("@/lib/graphql/bookingDateRequests/BookingDateRequests.ts");
const {
    BookingDateRequestStatusEnum,
} = require("@/lib/enums/bookingDateRequestStatus/BookingDateRequestStatusEnum");
const {
    BookingRequests,
} = require("@/lib/graphql/bookingRequests/BookingRequests.ts");
const {
    BookingTicketEmailLogs,
} = require("@/lib/graphql/bookingTicketEmailLogs/BookingTicketEmailLogs.ts");
const {
    BookingTicketEmailLogEmailsEnum,
} = require("@/lib/enums/bookingTicketEmailLogEmails/BookingTicketEmailLogEmailsEnum.ts");
const { Vouchers } = require("@/lib/graphql/vouchers/Vouchers.ts");
const VoucherStatusEnum = require("@/lib/enums/voucherStatus/VoucherStatusEnum");

class BookingDataComponent {
    async saveBookingData(apolloClient, bookingRequest, bookingDate, userId) {
        try {
            if (!apolloClient) {
                return {
                    IsSuccess: false,
                    Message: `Keine Datenbankschnittstelle übergeben.`,
                };
            }
            if (!bookingRequest) {
                return {
                    IsSuccess: false,
                    Message: `Keine Buchungsanfrage zum speichern gefunden.`,
                };
            }
            if (!bookingDate) {
                return {
                    IsSuccess: false,
                    Message: `Kein Buchungsdatum zum speichern gefunden.`,
                };
            }
            if (!userId) {
                return {
                    IsSuccess: false,
                    Message: `Kein Benutzer zum speichern gefunden.`,
                };
            }
            const voucher = await apolloClient
                .query({
                    query: Vouchers.Queries.Voucher,
                    fetchPolicy: "no-cache",
                    variables: {
                        id: bookingRequest.VoucherId,
                    },
                })
                .then(({ data }) => data?.voucher)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!voucher) {
                return {
                    IsSuccess: false,
                    Message: `Kein Gutschein für die Buchungsanfrage gefunden.`,
                };
            }
            if (bookingRequest.Id) {
                const savedOpenBookingTickets =
                    await this._saveOpenBookingTickets(
                        apolloClient,
                        bookingRequest.Id,
                        userId
                    );
                if (!savedOpenBookingTickets) {
                    return {
                        IsSuccess: false,
                        Message: `Die offenen Buchungstickets konnten nicht gespeichert werden.`,
                    };
                }
                if (!savedOpenBookingTickets.IsSuccess) {
                    return savedOpenBookingTickets;
                }
            }
            const savedBookingRequest = await apolloClient
                .mutate({
                    mutation: BookingRequests.Mutations.SaveBookingRequest,
                    fetchPolicy: "no-cache",
                    variables: {
                        bookingRequest: bookingRequest,
                    },
                })
                .then(({ data }) => data.saveBookingRequest)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!savedBookingRequest) {
                return {
                    IsSuccess: false,
                    Message: `Die Buchungsanfrage konnte nicht gespeichert werden.`,
                };
            }
            if (!savedBookingRequest.IsSuccess) {
                return savedBookingRequest;
            }
            const bookingTicket = {
                BookingRequestId: savedBookingRequest.StringData,
                BookingTicketStatusId: BookingTicketStatusEnum.DatesBooked,
                IsBindingBooking: false,
                CreatedBy: userId,
            };
            const savedBookingTicket = await apolloClient
                .mutate({
                    mutation: BookingTickets.Mutations.SaveBookingTicket,
                    fetchPolicy: "no-cache",
                    variables: {
                        bookingTicket: bookingTicket,
                    },
                })
                .then(({ data }) => data?.saveBookingTicket)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!savedBookingTicket) {
                return {
                    IsSuccess: false,
                    Message: `Das Buchungsticket konnte nicht gespeichert werden.`,
                };
            }
            if (!savedBookingTicket.IsSuccess) {
                return savedBookingTicket;
            }
            const bookingDateRequest = {
                Date: bookingDate,
                BookingDateRequestStatusId:
                    BookingDateRequestStatusEnum.DateBooked,
                BookingTicketId: savedBookingTicket.StringData,
                CreatedBy: userId,
            };
            const savedBookingDateRequest = await apolloClient
                .mutate({
                    mutation:
                        BookingDateRequests.Mutations.SaveBookingDateRequest,
                    fetchPolicy: "no-cache",
                    variables: {
                        bookingDateRequest: bookingDateRequest,
                    },
                })
                .then(({ data }) => data?.saveBookingDateRequest)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!savedBookingDateRequest) {
                return {
                    IsSuccess: false,
                    Message: `Die Buchungsdatumanfrage für ${moment(
                        bookingDateRequest.Date
                    ).format("DD.MM.YYYY")} konnte nicht gespeichert werden.`,
                };
            }
            if (!savedBookingDateRequest.IsSuccess) {
                return savedBookingDateRequest;
            }

            voucher.BookedAt = bookingDate;
            voucher.Status = VoucherStatusEnum.Booked;
            const savedVoucher = await apolloClient
                .mutate({
                    mutation: Vouchers.Mutations.SaveVoucher,
                    fetchPolicy: "no-cache",
                    variables: {
                        voucher: voucher,
                    },
                })
                .then(({ data }) => data?.saveVoucher)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!savedVoucher) {
                return {
                    IsSuccess: false,
                    Message: `Der Gutschein ${voucher.Id} konnte nicht gespeichert werden.`,
                };
            }
            if (!savedVoucher.IsSuccess) {
                return savedVoucher;
            }

            if (moment(voucher.BookedAt).isBefore()) {
                return {
                    IsSuccess: true,
                    Message: `Ihre Buchungsdaten wurden erfolgreich gespeichert.`,
                };
            }
            let sentBookingTicketEmailLogEmail = await apolloClient
                .mutate({
                    mutation:
                        BookingTicketEmailLogs.Mutations
                            .SendBookingTicketEmailLogEmail,
                    fetchPolicy: "no-cache",
                    variables: {
                        bookingTicketId: savedBookingTicket.StringData,
                        bookingTicketEmailLogEmail:
                            BookingTicketEmailLogEmailsEnum.CustomerBookingDatesBookedEmail,
                    },
                })
                .then(({ data }) => data?.sendBookingTicketEmailLogEmail)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!sentBookingTicketEmailLogEmail) {
                return {
                    IsSuccess: false,
                    Message: `Es konnte keine E-Mail an den Gastgeber bzgl. Ihrer Buchungsanfrage versendet werden.`,
                };
            }
            if (!sentBookingTicketEmailLogEmail.IsSuccess) {
                return sentBookingTicketEmailLogEmail;
            }
            sentBookingTicketEmailLogEmail = await apolloClient
                .mutate({
                    mutation:
                        BookingTicketEmailLogs.Mutations
                            .SendBookingTicketEmailLogEmail,
                    fetchPolicy: "no-cache",
                    variables: {
                        bookingTicketId: savedBookingTicket.StringData,
                        bookingTicketEmailLogEmail:
                            BookingTicketEmailLogEmailsEnum.HostBookingDatesBookedEmail,
                    },
                })
                .then(({ data }) => data?.sendBookingTicketEmailLogEmail)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!sentBookingTicketEmailLogEmail) {
                return {
                    IsSuccess: false,
                    Message: `Es konnte keine E-Mail an den Gastgeber bzgl. Ihrer Buchungsanfrage versendet werden.`,
                };
            }
            if (!sentBookingTicketEmailLogEmail.IsSuccess) {
                return sentBookingTicketEmailLogEmail;
            }
            return {
                IsSuccess: true,
                Message: `Ihre Buchungsdaten wurden erfolgreich gespeichert.`,
            };
        } catch (e) {
            console.error(e);
            return {
                IsSuccess: false,
                Message: e.message,
            };
        }
    }

    async saveBookingDateRequests(
        apolloClient,
        bookingTicketId,
        bookingDateRequests
    ) {
        try {
            if (!apolloClient) {
                return {
                    IsSuccess: false,
                    Message: `Keine Datenbankschnittstelle übergeben.`,
                };
            }
            if (!bookingTicketId) {
                return {
                    IsSuccess: false,
                    Message: `Keine Buchungsticket-Id gefunden.`,
                };
            }
            if (!bookingDateRequests) {
                return {
                    IsSuccess: false,
                    Message: `Keine Buchungsdatumsanfrage zum speichern gefunden.`,
                };
            }
            const bookingTicket = await apolloClient
                .query({
                    query: BookingTickets.Queries.BookingTicket,
                    fetchPolicy: "no-cache",
                    variables: {
                        id: bookingTicketId,
                    },
                })
                .then(({ data }) => data?.bookingTicket)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!bookingTicket) {
                return {
                    IsSuccess: false,
                    Message: `Kein Buchungsticket für ${bookingTicketId} gefunden.`,
                };
            }
            if (
                bookingTicket.IsBindingBooking &&
                bookingDateRequests.filter(
                    (b) =>
                        b.BookingDateRequestStatusId ==
                        BookingDateRequestStatusEnum.DateCanBeBooked
                ).length == 1
            ) {
                bookingDateRequests.find(
                    (b) =>
                        b.BookingDateRequestStatusId ==
                        BookingDateRequestStatusEnum.DateCanBeBooked
                ).BookingDateRequestStatusId =
                    BookingDateRequestStatusEnum.DateBooked;
                bookingTicket.BookingTicketStatusId =
                    BookingTicketStatusEnum.DatesBooked;
            } else if (
                !bookingTicket.IsBindingBooking &&
                bookingDateRequests.filter(
                    (b) =>
                        b.BookingDateRequestStatusId ==
                        BookingDateRequestStatusEnum.DateBooked
                ).length > 1
            ) {
                bookingTicket.BookingTicketStatusId =
                    BookingTicketStatusEnum.DatesCanBeBooked;
            } else if (
                bookingDateRequests.every(
                    (b) =>
                        b.BookingDateRequestStatusId ==
                        BookingDateRequestStatusEnum.DateRejected
                )
            ) {
                bookingTicket.BookingTicketStatusId =
                    BookingTicketStatusEnum.DatesRejected;
            } else {
                bookingTicket.BookingTicketStatusId =
                    BookingTicketStatusEnum.DatesCanBeBooked;
            }
            for (const bookingDateRequest of bookingDateRequests) {
                const savedBookingDateRequest = await apolloClient
                    .mutate({
                        mutation:
                            BookingDateRequests.Mutations
                                .SaveBookingDateRequest,
                        fetchPolicy: "no-cache",
                        variables: {
                            bookingDateRequest: bookingDateRequest,
                        },
                    })
                    .then(({ data }) => data?.saveBookingDateRequest)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                if (!savedBookingDateRequest) {
                    return {
                        IsSuccess: false,
                        Message: `Die Buchungsdatumsanfrage ${bookingDateRequest.Id} konnte nicht gespeichert werden.`,
                    };
                }
                if (!savedBookingDateRequest.IsSuccess) {
                    return savedBookingDateRequest;
                }
            }
            const savedBookingTicket = await apolloClient
                .mutate({
                    mutation: BookingTickets.Mutations.SaveBookingTicket,
                    fetchPolicy: "no-cache",
                    variables: {
                        bookingTicket: bookingTicket,
                    },
                })
                .then(({ data }) => data?.saveBookingTicket)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!savedBookingTicket) {
                return {
                    IsSuccess: false,
                    Message: `Das Buchungsticket konnte nicht gespeichert werden.`,
                };
            }
            if (!savedBookingTicket.IsSuccess) {
                return savedBookingTicket;
            }
            let bookingTicketEmailLogEmail;
            if (
                bookingTicket.BookingTicketStatusId ==
                BookingTicketStatusEnum.DatesCanBeBooked
            ) {
                bookingTicketEmailLogEmail =
                    BookingTicketEmailLogEmailsEnum.CustomerBookingDatesCanBeBookedEmail;
            } else if (
                bookingTicket.BookingTicketStatusId ==
                BookingTicketStatusEnum.DatesRejected
            ) {
                bookingTicketEmailLogEmail =
                    BookingTicketEmailLogEmailsEnum.CustomerBookingDatesRejectedEmail;
            }
            let sentBookingTicketEmailLogEmail;
            if (bookingTicketEmailLogEmail) {
                sentBookingTicketEmailLogEmail = await apolloClient
                    .mutate({
                        mutation:
                            BookingTicketEmailLogs.Mutations
                                .SendBookingTicketEmailLogEmail,
                        fetchPolicy: "no-cache",
                        variables: {
                            bookingTicketId: bookingTicket.Id,
                            bookingTicketEmailLogEmail:
                                bookingTicketEmailLogEmail,
                        },
                    })
                    .then(({ data }) => data?.sendBookingTicketEmailLogEmail)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                if (!sentBookingTicketEmailLogEmail) {
                    return {
                        IsSuccess: false,
                        Message: `Es konnte keine E-Mail an den Gast bzgl. der Buchungsanfragen versendet werden.`,
                    };
                }
                if (!sentBookingTicketEmailLogEmail.IsSuccess) {
                    return sentBookingTicketEmailLogEmail;
                }
            }
            if (
                bookingTicket.BookingTicketStatusId !=
                BookingTicketStatusEnum.DatesBooked
            ) {
                return {
                    IsSuccess: true,
                    Message:
                        "Die Buchungsanfrage wurde erfolgreich gespeichert.",
                };
            }
            const bookingRequest = await apolloClient
                .query({
                    query: BookingRequests.Queries.BookingRequest,
                    fetchPolicy: "no-cache",
                    variables: {
                        id: bookingTicket.BookingRequestId,
                    },
                })
                .then(({ data }) => data?.bookingRequest)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!bookingRequest) {
                return {
                    IsSuccess: false,
                    Message: `Es konnte keine Buchungsanfrage für das Buchungsticket ${bookingTicket.Id} geladen werden.`,
                };
            }
            const voucher = await apolloClient
                .query({
                    query: Vouchers.Queries.Voucher,
                    fetchPolicy: "no-cache",
                    variables: {
                        id: bookingRequest.VoucherId,
                    },
                })
                .then(({ data }) => data?.voucher)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!voucher) {
                return {
                    IsSuccess: false,
                    Message: `Es konnte kein Gutschein für die Buchungsanfrage ${bookingRequest.Id} geladen werden.`,
                };
            }
            const bookingDateRequest = bookingDateRequests.find(
                (b) =>
                    b.BookingDateRequestStatusId ==
                    BookingDateRequestStatusEnum.DateBooked
            );
            if (!bookingDateRequest) {
                return {
                    IsSuccess: false,
                    Message: `Es konnte keine gebuchte Buchungsdatumsanfrage herausgefiltert werden.`,
                };
            }
            voucher.BookedAt = bookingDateRequest.Date;
            voucher.Status = VoucherStatusEnum.Booked;
            const savedVoucher = await apolloClient
                .mutate({
                    mutation: Vouchers.Mutations.SaveVoucher,
                    fetchPolicy: "no-cache",
                    variables: {
                        voucher: voucher,
                    },
                })
                .then(({ data }) => data?.saveVoucher)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!savedVoucher) {
                return {
                    IsSuccess: false,
                    Message: `Der Gutschein ${voucher.Id} konnte nicht gespeichert werden.`,
                };
            }
            if (!savedVoucher.IsSuccess) {
                return savedVoucher;
            }
            if (moment(voucher.BookedAt).isBefore()) {
                return {
                    IsSuccess: true,
                    Message: `Ihre Buchungsdaten wurden erfolgreich gespeichert.`,
                };
            }
            sentBookingTicketEmailLogEmail = await apolloClient
                .mutate({
                    mutation:
                        BookingTicketEmailLogs.Mutations
                            .SendBookingTicketEmailLogEmail,
                    fetchPolicy: "no-cache",
                    variables: {
                        bookingTicketId: bookingTicket.Id,
                        bookingTicketEmailLogEmail:
                            BookingTicketEmailLogEmailsEnum.HostBookingDatesBookedEmail,
                    },
                })
                .then(({ data }) => data?.sendBookingTicketEmailLogEmail)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!sentBookingTicketEmailLogEmail) {
                return {
                    IsSuccess: false,
                    Message: `Es konnte keine E-Mail an den Gastgeber bzgl. Ihrer Buchungsanfrage versendet werden.`,
                };
            }
            if (!sentBookingTicketEmailLogEmail.IsSuccess) {
                return sentBookingTicketEmailLogEmail;
            }
            sentBookingTicketEmailLogEmail = await apolloClient
                .mutate({
                    mutation:
                        BookingTicketEmailLogs.Mutations
                            .SendBookingTicketEmailLogEmail,
                    fetchPolicy: "no-cache",
                    variables: {
                        bookingTicketId: bookingTicket.Id,
                        bookingTicketEmailLogEmail:
                            BookingTicketEmailLogEmailsEnum.CustomerBookingDatesBookedEmail,
                    },
                })
                .then(({ data }) => data?.sendBookingTicketEmailLogEmail)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!sentBookingTicketEmailLogEmail) {
                return {
                    IsSuccess: false,
                    Message: `Es konnte keine E-Mail an den Gast versendet werden.`,
                };
            }
            if (!sentBookingTicketEmailLogEmail.IsSuccess) {
                return sentBookingTicketEmailLogEmail;
            }
            return {
                IsSuccess: true,
                Message: "Die Buchungsanfrage wurde erfolgreich gespeichert.",
            };
        } catch (e) {
            console.error(e);
            return {
                IsSuccess: false,
                Message: e.message,
            };
        }
    }

    async _saveOpenBookingTickets(apolloClient, bookingRequestId, userId) {
        try {
            if (!apolloClient) {
                return {
                    IsSuccess: false,
                    Message: `Keine Datenbankschnittstelle übergeben.`,
                };
            }
            if (!bookingRequestId) {
                return {
                    IsSuccess: false,
                    Message: `Keine Buchungsanfrage gefunden.`,
                };
            }
            if (!userId) {
                return {
                    IsSuccess: false,
                    Message: `Kein Benutzer gefunden.`,
                };
            }
            let bookingTickets = await apolloClient
                .query({
                    query: BookingTickets.Queries
                        .BookingTicketsByBookingRequestId,
                    fetchPolicy: "no-cache",
                    variables: {
                        bookingRequestId: bookingRequestId,
                    },
                })
                .then(({ data }) => data?.bookingTicketsByBookingRequestId)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!bookingTickets) {
                return {
                    IsSuccess: false,
                    Message: `Es konnten keine Buchungstickets für Buchungsanfrage ${bookingRequestId} geladen werden.`,
                };
            }
            bookingTickets = bookingTickets.filter(
                (b) =>
                    b.BookingTicketStatusId !=
                        BookingTicketStatusEnum.DatesCancelled &&
                    b.BookingTicketStatusId !=
                        BookingTicketStatusEnum.DatesRejected
            );
            if (!bookingTickets) {
                return {
                    IsSuccess: false,
                    Message: `Die Buchungstickets konnten nicht gefiltert werden.`,
                };
            }
            for (const bookingTicket of bookingTickets) {
                let bookingDateRequests = await apolloClient
                    .query({
                        query: BookingDateRequests.Queries
                            .BookingDateRequestsByBookingTicketId,
                        fetchPolicy: "no-cache",
                        variables: {
                            bookingTicketId: bookingTicket.Id,
                        },
                    })
                    .then(
                        ({ data }) => data?.bookingDateRequestsByBookingTicketId
                    )
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                if (!bookingDateRequests) {
                    return {
                        IsSuccess: false,
                        Message: `Es konnten keine Buchungsdatumsanfragen für Buchungsticket ${bookingTicket.Id} geladen werden.`,
                    };
                }
                bookingDateRequests = bookingDateRequests.filter(
                    (b) =>
                        b.BookingDateRequestStatusId !=
                            BookingDateRequestStatusEnum.DateRejected &&
                        b.BookingDateRequestStatusId !=
                            BookingDateRequestStatusEnum.DateCancelled
                );
                if (!bookingDateRequests) {
                    return {
                        IsSuccess: false,
                        Message: `Die Buchungsdatumsanfragen konnten nicht gefiltert werden.`,
                    };
                }
                for (const bookingDateRequest of bookingDateRequests) {
                    bookingDateRequest.BookingDateRequestStatusId =
                        BookingDateRequestStatusEnum.DateRejected;
                    bookingDateRequest.UpdatedBy = userId;
                    const savedBookingDateRequest = await apolloClient
                        .mutate({
                            mutation:
                                BookingDateRequests.Mutations
                                    .SaveBookingDateRequest,
                            fetchPolicy: "no-cache",
                            variables: {
                                bookingDateRequest: bookingDateRequest,
                            },
                        })
                        .then(({ data }) => data?.saveBookingDateRequest)
                        .catch((e) => {
                            console.error(e);
                            return undefined;
                        });
                    if (!savedBookingDateRequest) {
                        return {
                            IsSuccess: false,
                            Message: `Die Buchungsdatumsanfrage ${bookingDateRequest.Id} konnte nicht gespeichert werden.`,
                        };
                    }
                    if (!savedBookingDateRequest.IsSuccess) {
                        return savedBookingDateRequest;
                    }
                }

                bookingTicket.BookingTicketStatusId =
                    BookingTicketStatusEnum.DatesRejected;
                bookingTicket.UpdatedBy = userId;
                const savedBookingTicket = await apolloClient
                    .mutate({
                        mutation: BookingTickets.Mutations.SaveBookingTicket,
                        fetchPolicy: "no-cache",
                        variables: {
                            bookingTicket: bookingTicket,
                        },
                    })
                    .then(({ data }) => data?.saveBookingTicket)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                if (!savedBookingTicket) {
                    return {
                        IsSuccess: false,
                        Message: `Das Buchungsticket ${bookingTicket.Id} konnte nicht gespeichert werden.`,
                    };
                }
                if (!savedBookingTicket.IsSuccess) {
                    return savedBookingTicket;
                }
            }
            return {
                IsSuccess: true,
            };
        } catch (e) {
            console.error(e);
            return {
                IsSuccess: false,
                Message: e.message,
            };
        }
    }
}

module.exports = BookingDataComponent;
