<template lang="pug">
.card
  tD-loading-overlay(v-if="$apollo.loading")
  .card-body
    .card-title
      .row
        .col-12
          .text-left
            h5 {{header}}

    .card-text.p-4
      .text-center
        .sales-text(v-if="isLoading") Lädt...
        .sales-text(v-else) {{ salesValue | formatEuro }}

</template>

<script>
import EventBus from "../../../views/event-bus";
import { ItemsComponent } from "@/lib/components/items/ItemsComponent";
import VoucherStatusEnum from "@/lib/enums/voucherStatus/VoucherStatusEnum";
import moment from "moment";

export default {
    name: "SalesFutureArrivalValueWidget",
    props: {
        header: String,
    },
    data() {
        return {
            hostId: this.$session.get("hostId"),
            salesValue: 0,

            isLoading: false,
        };
    },
    async mounted() {
        try {
            EventBus.$on(
                "changeHostId",
                function (hostId) {
                    this.hostId = hostId;
                    this.loadSalesValue();
                }.bind(this)
            );
            this.loadSalesValue();
        } catch (e) {
            console.error(e);
            this.$alert(e.message);
        }
    },
    methods: {
        async loadSalesValue() {
            try {
                const itemsComponent = new ItemsComponent();
                this.isLoading = true;
                const salesValue = await itemsComponent.getItemsValue(
                    this.$apollo,
                    undefined,
                    this.hostId,
                    undefined,
                    undefined,
                    moment().startOf("month").toDate(),
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    [VoucherStatusEnum.Booked, VoucherStatusEnum.Redeemed]
                );
                this.isLoading = false;
                this.salesValue = salesValue;
            } catch (e) {
                console.log(e);
                this.$alert(e.message);
            }
        },
    },
};
</script>

<style scoped></style>
