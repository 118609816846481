const { Users } = require("@/lib/graphql/users/Users.ts");

class UsersComponent {
    async updateUserPassword(apollo, userId, password) {
        try {
            if (!apollo) {
                return {
                    IsSuccess: false,
                    Message: `Kein Apollo-Client gefunden.`,
                };
            }
            if (!userId) {
                return {
                    IsSuccess: false,
                    Message: `Kein Benutzer zum speichern gefunden.`,
                };
            }
            if (!password) {
                return {
                    IsSuccess: false,
                    Message: `Kein Passwort zum speichern gefunden.`,
                };
            }
            const updatedUserPassword = await apollo
                .mutate({
                    mutation: Users.Mutations.UpdateUserPassword,
                    fetchPolicy: "no-cache",
                    variables: {
                        userId: userId,
                        password: password,
                    },
                })
                .then(({ data }) => data?.updateUserPassword)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!updatedUserPassword) {
                return undefined;
            }
            return updatedUserPassword;
        } catch (e) {
            console.error(e);
            return {
                IsSuccess: false,
                Message: e.message,
            };
        }
    }
}

module.exports = UsersComponent;
