import moment from "moment";

let VueFilter = {
    formatDateFilter: {
        name: "formatDate",
        func: (value) => {
            if (value) {
                return moment(String(value)).format("DD.MM.YYYY");
            }
        },
    },
    formatDateTimeFilter: {
        name: "formatDateTime",
        func: (value) => {
            if (value) {
                return moment(String(value)).format("DD.MM.YYYY HH:mm");
            }
        },
    },
    formatDatePickerValueFilter: {
        name: "formatDatePickerValue",
        func: (value) => {
            if (value) {
                return moment(String(value)).format("YYYY-MM-DD");
            }
        },
    },
    formatDateWithDayFilter: {
        name: "formatDateWithDay",
        func: (value) => {
            if (value) {
                const date = moment(String(value)).lang("de");
                return date.format("dd") + ". " + date.format("DD.MM.YYYY");
            }
        },
    },
    formatPercentageFilter: {
        name: "formatPercentage",
        func: (value) => {
            if (value) {
                return parseFloat(value).toFixed(2).toString() + " %";
            }
            return parseFloat(0.0).toFixed(2) + " %";
        },
    },
    formatEuroFilter: {
        name: "formatEuro",
        func: (value) => {
            if (!value) {
                return "0,00 €";
            }
            return `${parseFloat(parseFloat(value).toFixed(2)).toLocaleString(
                "de-DE",
                { minimumFractionDigits: 2 }
            )} €`;
        },
    },
};

export default VueFilter;
