<template lang="pug">
  .tb-panel(v-if="isReady")
    .tb-panel-header
      .row
        .col-12.col-lg-4
          .tb-ticket-no {{$t("touriBook.ticketNo")}}:&nbsp;
            span.font-weight-bold {{ bookingTicket.Id }}
        .col-12.col-lg-4
          .tb-ticket-date {{$t("touriBook.ticketDate")}}:&nbsp;
            span.font-weight-bold {{ bookingTicket.CreatedAt | formatDate }}
        .col-12.col-lg-4
          .tb-ticket-voucher-no {{$t("touriBook.ticketVoucherNo")}}:&nbsp;
            span.font-weight-bold {{ voucher.Id }}
    .tb-panel-body
      .row
        .col-12
          .table-responsive
            table.table-sm.table-borderless
              tbody
                tr
                  td.align-top {{$t("touriBook.location")}}:&nbsp;
                  td
                    .font-weight-bold {{ host.Match1 }}
                tr
                  td.align-top {{$t("touriBook.guestName")}}:&nbsp;
                  td
                    .font-weight-bold {{ bookingRequest.FirstName }} {{ bookingRequest.LastName }}
                    | {{ bookingRequest.Street }}
                    br
                    | {{ bookingRequest.ZipCode }} {{ bookingRequest.City }}
                tr
                  td
                    .tb-ticket-status {{$t("touriBook.ticketStatus")}}:&nbsp;
                  td.book
                    span(v-if="bookingTicket.BookingTicketStatusId == BookingTicketStatusEnum.DatesRequested")
                      .others.cancellation.font-weight-bold(v-if="bookingTicket.IsBindingBooking")
                        | {{$t("touriBook.bookingTicketStatus." + bookingTicketStatus.Description)}}&nbsp;
                        | ({{$t("touriBook.bindingBooking")}})
                      .others.cancellation.font-weight-bold(v-else)
                        | {{$t("touriBook.bookingTicketStatus." + bookingTicketStatus.Description)}}
                    span(v-else-if="bookingTicket.BookingTicketStatusId == BookingTicketStatusEnum.DatesCancelled").cancellation.font-weight-bold
                      | {{$t("touriBook.bookingTicketStatus." + bookingTicketStatus.Description)}}
                    span(v-else-if="bookingTicket.BookingTicketStatusId == BookingTicketStatusEnum.DatesBooked").booked.font-weight-bold
                      | {{$t("touriBook.bookingTicketStatus." + bookingTicketStatus.Description)}}
                    span(v-else).others.cancellation.font-weight-bold
                      | {{$t("touriBook.bookingTicketStatus." + bookingTicketStatus.Description)}}

                tr(v-if="bookingTicket.BookingTicketStatusId == BookingTicketStatusEnum.DatesCanBeBooked")
                  td.align-top {{$t("touriBook.datesCanBeBooked")}}:
                  td.font-weight-bold
                    touribook-date-request-template(:dateRequests="bookingDateRequests.filter(b => b.BookingDateRequestStatusId == BookingDateRequestStatusEnum.DateCanBeBooked)", :Nights="product.Uebernachtungen")
                tr(v-else-if="bookingTicket.BookingTicketStatusId == BookingTicketStatusEnum.DatesBooked")
                  td.align-top {{$t("touriBook.bookedDates")}}:
                  td.font-weight-bold
                    p.m-0 {{ voucher.BookedAt | formatDateWithDay }} - {{moment(voucher.BookedAt).add(product.Uebernachtungen, "days") | formatDateWithDay}}
                tr(v-else-if="bookingTicket.BookingTicketStatusId == BookingTicketStatusEnum.DatesCancelRequested")
                  td.align-top {{$t("touriBook.cancelRequested")}}:
                  td.font-weight-bold
                    touribook-date-request-template(:dateRequests="bookingDateRequests.filter(b => b.BookingDateRequestStatusId == BookingDateRequestStatusEnum.DateCancelRequested)", :Nights="product.Uebernachtungen")
                tr(v-else-if="bookingTicket.BookingTicketStatusId == BookingTicketStatusEnum.DatesRejected")
                  td.align-top {{$t("touriBook.rejectedDates")}}:
                  td.font-weight-bold
                    touribook-date-request-template(:dateRequests="bookingDateRequests.filter(b => b.BookingDateRequestStatusId == BookingDateRequestStatusEnum.DateRejected)", :Nights="product.Uebernachtungen")
                tr(v-else-if="bookingTicket.BookingTicketStatusId == BookingTicketStatusEnum.DatesCancelled")
                  td.align-top {{$t("touriBook.canceledDates")}}:
                  td.font-weight-bold
                    touribook-date-request-template(:dateRequests="bookingDateRequests.filter(b => b.BookingDateRequestStatusId == BookingDateRequestStatusEnum.DateCancelled)", :Nights="product.Uebernachtungen")

      .row
        .col-12
          .tb-ticket-booking-dates
            .table-responsive
              table.table.table-sm.table-borderless(v-if="bookingTicket.BookingTicketStatusId == BookingTicketStatusEnum.DatesRequested")
                tbody
                  tr(v-for="dateRequest in bookingDateRequests")
                    td
                      img.emoji(v-if="dateRequest.BookingDateRequestStatusId == BookingDateRequestStatusEnum.DateCanBeBooked" src="https://td-cloud.s3.eu-central-1.amazonaws.com/Images/Dashboard/happy.png")
                      img.emoji(v-else-if="dateRequest.BookingDateRequestStatusId == BookingDateRequestStatusEnum.DateRejected" src="https://td-cloud.s3.eu-central-1.amazonaws.com/Images/Dashboard/50-Tear1.png")
                    td {{dateRequest.Date | formatDateWithDay}} - {{moment(dateRequest.Date).add(product.Uebernachtungen, "days") | formatDateWithDay}}
                    td
                      .form-check
                        input.form-check-input(type="radio", v-model="dateRequest.BookingDateRequestStatusId", :value="BookingDateRequestStatusEnum.DateCanBeBooked" @change="changeBookingDateRequestStatus(dateRequest.Id, BookingDateRequestStatusEnum.DateCanBeBooked)" :id="'accept' + dateRequest.Id")
                        label.form-check-label(style="font-size: 12px;" :for="'accept' + dateRequest.Id") {{$t("constants.confirm.y")}}
                    td
                      .form-check
                        input.form-check-input(type="radio", v-model="dateRequest.BookingDateRequestStatusId", :value="BookingDateRequestStatusEnum.DateRejected" @change="changeBookingDateRequestStatus(dateRequest.Id, BookingDateRequestStatusEnum.DateRejected)" :id="'decline' + dateRequest.Id")
                        label.form-check-label(style="font-size: 12px;" :for="'decline' + dateRequest.Id") {{$t("constants.confirm.n")}}
                    td(style="width:250px;")
                      span(v-if="dateRequest.BookingDateRequestStatusId == BookingDateRequestStatusEnum.DateRejected")
                        label.form-label {{$t("touriBook.StatusType.Reason")}}
                        select(style="font-size: 12px;" v-model="dateRequest.RefusalReason").form-control
                          option(:value="1") {{$t("touriBook.StatusType.RefusalReason1")}}
                          option(:value="2") {{$t("touriBook.StatusType.RefusalReason2")}}
                          option(:value="3") {{$t("touriBook.StatusType.RefusalReason3")}}

      .row
        .col-12
          router-link.button.button-primary.button-tdays.float-none.float-lg-right.mb-1.mr-2(v-if="DisplayDetailButton" :to="{ path: `/touribook/detail/${bookingTicketId}` }" tag="button")
            | {{$t("constants.buttons.showDetails")}}
          button.button.button-secondary.button-tdays.float-none.float-lg-right.mb-1.mr-2(@click="saveBookingDateRequests" v-if="bookingTicket.BookingTicketStatusId == BookingTicketStatusEnum.DatesRequested")
            | {{$t("touriBook.btnSendDates")}}
          button.button.button-secondary.button-tpics.float-none.float-lg-right.mb-1.mr-2(@click="cancelBookingTicket" v-if="bookingTicket.BookingTicketStatusId == BookingTicketStatusEnum.DatesBooked")
            | {{$t("touriBook.btnCancelTicket")}}
          .float-right(v-if="bookingTicket.BookingTicketStatusId == BookingTicketStatusEnum.DatesCancelRequested")
            button.button.button-secondary.button-tpics.float-none.float-lg-right.mb-1.mr-2(@click="cancelRequestedBookingTicket(false)")
              | {{$t("touriBook.btnDeclineCancel")}}
            button.button.button-secondary.button-tdays.float-none.float-lg-right.mb-1.mr-2(@click="cancelRequestedBookingTicket(true)")
              | {{$t("touriBook.btnAcceptCancel")}}

</template>

<script>
import moment from "moment";
import { BookingDateRequestStatusEnum } from "../../../lib/enums/bookingDateRequestStatus/BookingDateRequestStatusEnum";
import { BookingTicketStatusEnum } from "../../../lib/enums/bookingTicketStatus/BookingTicketStatusEnum";
import { CancelBookingTicketComponent } from "../../../lib/components/cancelBookingTicket/CancelBookingTicketComponent";
import EventBus from "../../event-bus";
import { BookingTickets } from "@/lib/graphql/bookingTickets/BookingTickets.ts";
import { BookingRequests } from "@/lib/graphql/bookingRequests/BookingRequests.ts";
import { Vouchers } from "@/lib/graphql/vouchers/Vouchers.ts";
import { Products } from "@/lib/graphql/products/Products.ts";
import { Hosts } from "@/lib/graphql/hosts/Hosts.ts";
import { BookingDateRequests } from "@/lib/graphql/bookingDateRequests/BookingDateRequests.ts";
import { BookingTicketStatus } from "@/lib/graphql/bookingTicketStatus/BookingTicketStatus.ts";
import BookingDataComponent from "@/lib/components/bookingData/BookingDataComponent";

export default {
    name: "touribook-request-panel",
    props: {
        bookingTicketId: {
            type: String,
            allowNull: false,
        },
        DisplayDetailButton: Boolean,
    },
    data() {
        return {
            bookingTicket: undefined,
            bookingTicketStatus: undefined,
            bookingRequest: undefined,
            voucher: undefined,
            product: undefined,
            host: undefined,
            bookingDateRequests: [],
            BookingDateRequestStatusEnum: BookingDateRequestStatusEnum,
            BookingTicketStatusEnum: BookingTicketStatusEnum,
            moment: moment,
        };
    },
    async mounted() {
        try {
            await this.loadBookingTicket();
            if (
                this.bookingTicket.BookingTicketStatusId !=
                BookingTicketStatusEnum.DatesRequested
            ) {
                return;
            }
            if (this.bookingDateRequests.length == 0) {
                return;
            }
            this.bookingDateRequests
                .filter((b) => this.bookingDateRequests.indexOf(b) == 0)
                .forEach(
                    (b) =>
                        (b.BookingDateRequestStatusId =
                            BookingDateRequestStatusEnum.DateCanBeBooked)
                );
            this.bookingDateRequests
                .filter((b) => this.bookingDateRequests.indexOf(b) > 0)
                .forEach(
                    (b) =>
                        (b.BookingDateRequestStatusId =
                            BookingDateRequestStatusEnum.DateRejected)
                );
        } catch (e) {
            console.error(e);
            this.$alert(e.message);
        }
    },
    methods: {
        async loadBookingTicket() {
            try {
                const bookingTicket = await this.$apollo
                    .query({
                        query: BookingTickets.Queries.BookingTicket,
                        fetchPolicy: "no-cache",
                        variables: {
                            id: this.bookingTicketId,
                        },
                    })
                    .then(({ data }) => data?.bookingTicket)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                if (!bookingTicket) {
                    return;
                }
                this.bookingTicket = bookingTicket;
                await this.loadBookingDateRequests();
                this.loadBookingRequest();
                this.loadBookingTicketStatus();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadBookingTicketStatus() {
            try {
                const bookingTicketStatus = await this.$apollo
                    .query({
                        query: BookingTicketStatus.Queries
                            .BookingTicketStatusById,
                        variables: {
                            id: this.bookingTicket.BookingTicketStatusId,
                        },
                    })
                    .then(({ data }) => data?.bookingTicketStatusById)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                if (!bookingTicketStatus) {
                    return;
                }
                this.bookingTicketStatus = bookingTicketStatus;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadBookingDateRequests() {
            try {
                const bookingDateRequests = await this.$apollo
                    .query({
                        query: BookingDateRequests.Queries
                            .BookingDateRequestsByBookingTicketId,
                        fetchPolicy: "no-cache",
                        variables: {
                            bookingTicketId: this.bookingTicket.Id,
                        },
                    })
                    .then(
                        ({ data }) => data?.bookingDateRequestsByBookingTicketId
                    )
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                if (!bookingDateRequests) {
                    return;
                }
                this.bookingDateRequests = bookingDateRequests;
            } catch (e) {
                console.error(e);
                return undefined;
            }
        },
        async loadBookingRequest() {
            try {
                const bookingRequest = await this.$apollo
                    .query({
                        query: BookingRequests.Queries.BookingRequest,
                        fetchPolicy: "no-cache",
                        variables: {
                            id: this.bookingTicket?.BookingRequestId,
                        },
                    })
                    .then(({ data }) => data?.bookingRequest)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                if (!bookingRequest) {
                    return;
                }
                this.bookingRequest = bookingRequest;
                await this.loadVoucher();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadVoucher() {
            try {
                const voucher = await this.$apollo
                    .query({
                        query: Vouchers.Queries.Voucher,
                        fetchPolicy: "no-cache",
                        variables: {
                            id: this.bookingRequest?.VoucherId,
                        },
                    })
                    .then(({ data }) => data?.voucher)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                if (!voucher) {
                    return;
                }
                this.voucher = voucher;
                await this.loadProduct();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadProduct() {
            try {
                const product = await this.$apollo
                    .query({
                        query: Products.Queries.Product,
                        fetchPolicy: "no-cache",
                        variables: {
                            id: this.voucher?.ProductId,
                        },
                    })
                    .then(({ data }) => data?.product)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                if (!product) {
                    return;
                }
                this.product = product;
                await this.loadHost();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadHost() {
            try {
                const host = await this.$apollo
                    .query({
                        query: Hosts.Queries.Host,
                        fetchPolicy: "no-cache",
                        variables: {
                            id: this.product?.HostId,
                        },
                    })
                    .then(({ data }) => data?.host)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                if (!host) {
                    return;
                }
                this.host = host;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        changeBookingDateRequestStatus(
            dateRequestId,
            BookingDateRequestStatusId
        ) {
            try {
                if (
                    BookingDateRequestStatusId !=
                    BookingDateRequestStatusEnum.DateCanBeBooked
                ) {
                    this.bookingDateRequests.find(
                        (b) => b.Id == dateRequestId
                    ).BookingDateRequestStatusId = BookingDateRequestStatusId;
                    return;
                }
                if (!this.bookingTicket.IsBindingBooking) {
                    return;
                }
                this.bookingDateRequests.forEach(
                    (b) =>
                        (b.BookingDateRequestStatusId =
                            BookingDateRequestStatusEnum.DateRejected)
                );
                const bookingDateRequest = this.bookingDateRequests.find(
                    (b) => b.Id == dateRequestId
                );
                bookingDateRequest.BookingDateRequestStatusId =
                    BookingDateRequestStatusEnum.DateCanBeBooked;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async saveBookingDateRequests() {
            try {
                const confirmed = await this.$confirm(
                    this.$t(
                        "constants.confirm.touriBookDateRequestQ1"
                    ).toString(),
                    this.$t("constants.confirm.globalTitle").toString()
                )
                    .then()
                    .catch((e) => e);
                if (!confirmed) {
                    return;
                }
                const bookingDataComponent = new BookingDataComponent();
                EventBus.$emit("changeLoadingState", true);
                const savedBookingDateRequests =
                    await bookingDataComponent.saveBookingDateRequests(
                        this.$apollo,
                        this.bookingTicket.Id,
                        this.bookingDateRequests
                    );
                EventBus.$emit("changeLoadingState", false);
                if (!savedBookingDateRequests) {
                    return this.$alert(
                        "Die Buchungsanfragen konnten nicht gespeichert werden."
                    );
                }
                if (savedBookingDateRequests.Message) {
                    this.$alert(savedBookingDateRequests.Message);
                }
                if (savedBookingDateRequests.IsSuccess) {
                    await this.loadBookingTicket();
                }
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        cancelRequestedBookingTicket(cancelBookingTicket) {
            try {
                EventBus.$emit("changeLoadingState", true);
                const cancelBookingTicketComponent =
                    new CancelBookingTicketComponent();
                cancelBookingTicketComponent.cancelRequestedBookingTicket(
                    this.$apollo,
                    this.bookingTicket.Id,
                    cancelBookingTicket,
                    this.$session.get("lang"),
                    (response) => {
                        if (!response) {
                            EventBus.$emit("changeLoadingState", false);
                            return;
                        }
                        if (response.message) {
                            this.$alert(response.message);
                        }
                        if (response.success) {
                            this.$apollo.queries.bookingTicket.refetch();
                            EventBus.$emit("changeLoadingState", false);
                        }
                    }
                );
            } catch (e) {
                EventBus.$emit("changeLoadingState", false);
                this.$alert(
                    e.message,
                    this.$t("constants.messages.alertTitle").toString()
                );
            }
        },
        cancelBookingTicket() {
            try {
                this.$prompt(this.$t("constants.messages.enterCode").toString())
                    .then((code) => {
                        if (
                            this.voucher.Guid.slice(-6).toLowerCase() !=
                            code.toLowerCase()
                        ) {
                            this.$alert(
                                this.$t(
                                    "constants.messages.noCodeMatch"
                                ).toString(),
                                this.$t(
                                    "constants.messages.alertTitle"
                                ).toString()
                            );
                            return;
                        }
                        EventBus.$emit("changeLoadingState", true);
                        const cancelBookingTicketComponent =
                            new CancelBookingTicketComponent();
                        cancelBookingTicketComponent.cancelBookingTicket(
                            this.$apollo,
                            this.bookingTicket.Id,
                            this.$session.get("userId"),
                            this.$session.get("lang"),
                            (response) => {
                                if (!response) {
                                    EventBus.$emit("changeLoadingState", false);
                                    return;
                                }
                                if (response.Message) {
                                    this.$alert(response.message);
                                }
                                if (response.IsSuccess) {
                                    EventBus.$emit("changeLoadingState", false);
                                    this.$apollo.queries.bookingTicket.refetch();
                                }
                            }
                        );
                    })
                    .catch(() => {
                        EventBus.$emit("changeLoadingState", false);
                        return;
                    });
            } catch (e) {
                EventBus.$emit("changeLoadingState", false);
                this.$alert(
                    e.message,
                    this.$t("constants.messages.alertTitle").toString()
                );
            }
        },
    },
    computed: {
        isReady() {
            try {
                if (!this.bookingTicket) {
                    return false;
                }
                if (!this.bookingRequest) {
                    return false;
                }
                if (!this.voucher) {
                    return false;
                }
                if (!this.product) {
                    return false;
                }
                if (!this.host) {
                    return false;
                }
                if (!this.bookingTicketStatus) {
                    return false;
                }
                return true;
            } catch (e) {
                console.error(e);
                return false;
            }
        },
    },
};
</script>
