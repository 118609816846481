<template lang="pug">
.wrapper(style="padding: 0rem")
  p.m-0(v-for="dateRequest in dateRequests")
    span {{dateRequest.Date | formatDateWithDay}} - {{moment(dateRequest.Date).add(Nights, "days")  | formatDateWithDay}}
    br(v-if="dateRequests.indexOf(dateRequest) < dateRequests.length - 1")
</template>

<script>
import moment from "moment";
import { BookingDateRequestStatusEnum } from "../../../lib/enums/bookingDateRequestStatus/BookingDateRequestStatusEnum";

export default {
    name: "date-request-template",
    props: {
        dateRequests: Array,
        Nights: Number,
    },
    data() {
        return {
            moment: moment,
            BookingDateRequestStatusEnum: BookingDateRequestStatusEnum,
        };
    },
};
</script>
