<template lang="pug">
.wrapper
  tD-loading-overlay(v-if="$apollo.loading")
  .row
    .col-12
      h2 {{$t("touriBook.Headline1")}}

  tableFilter(:isTouribook="true" :searchHeadline="'touriBook.Headline2'" :searchPlaceholder="'touriBook.searchInputPlaceholder'")

  .row.mt-3
    .col-12
      h5 {{$t("touriBook.Headline3")}}
    pagesize(:isTouribook="true")
    .col-lg-2
    .col-12.col-sm-6.col-lg-6
      .button.button-primary.button-tdays.filter-touriBook.w-100(type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample") {{$t("touriBook.statusFilter")}}
      .collapse(id="collapseExample")
        .card.card-body
          ul.list-group
            li.list-group-item.border-0(v-for="bookingTicketStatus in BookingTicketStatus" v-bind:key="bookingTicketStatus.Id",)
              .form-check
                input.form-check-input(type="checkbox", :id="bookingTicketStatus.Id", :value="parseFloat(bookingTicketStatus.Id)"
                , v-model="selectedBookingTicketStatus", :checked="selectedBookingTicketStatus.includes(bookingTicketStatus.Id)")
                label.form-check-label(:for="bookingTicketStatus.Id") {{$t("touriBook.bookingTicketStatus." + bookingTicketStatus.Description)}}

  .row.mt-3
    .col-12
      .tb-panel-wrapper(v-if='bookingTickets && bookingTickets.length > 0')
        touribook-request-panel(
          v-for="bookingTicket in bookingTickets"
          :key="bookingTicket.Id"
          :booking-ticket-id="bookingTicket.Id"
          :DisplayDetailButton="true",
        )

  pagination(:isTouribook="true" :DataCount="this.bookingTicketsCount" :PageSize="this.pageSize" :DataCountText="'touriBook.BookingTicketsCount'")

</template>

<script>
import { GetBookingTicketStatusQuery } from "../../../lib/graphql/queries/bookingTicketStatus/GetBookingTicketStatusQuery";
import EventBus from "../../../views/event-bus";
import { BookingTickets } from "@/lib/graphql/bookingTickets/BookingTickets.ts";

export default {
    name: "TouribookList",
    props: {
        slug: String,
    },
    data() {
        return {
            bookingTickets: [],
            bookingTicketsCount: 0,
            BookingTicketStatus: [],
            hostId: this.$session.get("hostId"),

            pageSize: 10,
            offset: 0,
            privateFilterValue: "",
            filterValue: "",

            selectedBookingTicketStatus: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        };
    },
    mounted() {
        EventBus.$on(
            "changeHostId",
            function (hostId) {
                this.hostId = hostId;
            }.bind(this)
        );
        EventBus.$on(
            "changeTouribookOffset",
            function (offset) {
                this.offset = offset;
            }.bind(this)
        );
        EventBus.$on(
            "changeTouribookPageSize",
            function (pageSize) {
                this.pageSize = parseFloat(pageSize);
            }.bind(this)
        );
        EventBus.$on(
            "applyTouribookFilter",
            function (filterValue) {
                this.offset = 0;
                this.filterValue = filterValue;
            }.bind(this)
        );
    },
    apollo: {
        bookingTickets: {
            query: BookingTickets.Queries.BookingTickets,
            loadingKey: "loading",
            variables() {
                return {
                    hostIds: [this.hostId],
                    filterValue: this.filterValue,
                    ticketStatus: this.selectedBookingTicketStatus,
                    offset: this.offset,
                    limit: this.pageSize,
                };
            },
        },
        bookingTicketsCount: {
            query: BookingTickets.Queries.BookingTicketsCount,
            loadingKey: "loading",
            variables() {
                return {
                    hostIds: [this.hostId],
                    filterValue: this.filterValue,
                    ticketStatus: this.selectedBookingTicketStatus,
                };
            },
            result({ data }) {
                if (!data || !data.bookingTicketsCount) {
                    return;
                }
                EventBus.$emit(
                    "changeTouribookDataCount",
                    data.bookingTicketsCount
                );
            },
        },
        BookingTicketStatus: {
            query: GetBookingTicketStatusQuery,
            loadingKey: "loading",
        },
    },
};
</script>

<style scoped></style>
