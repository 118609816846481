<template lang="pug">
  .container-fluid
    .row
      .col-12
        h2 {{$t("vouchers.list.headline")}}

      .col-12.mt-2
        h5 {{$t("vouchers.list.overviewHeadline")}}
      .col-12.mt-2
        multiselect(
          v-model="selectedVoucherStatus"
          deselect-label="Entfernen",
          select-label="Ausgewählen"
          track-by="Id",
          label="Description",
          placeholder="Select one",
          :options="voucherStatus",
          :searchable="false",
          :allow-empty="false",
          :multiple="true"
          @input="changeVoucherStatus"
        )
      .col-12.mt-2
        select.custom-select(v-model="sort")
          option(selected, value="new") Erstelldatum
          option(value="bookingDate") Buchungsdatum
      .col-lg-6.col-md-12.mt-2
        label von
        input.form-control.custom-control(type="date", v-model="from")
      .col-lg-6.col-md-12.mt-2
        label bis
        input.form-control.custom-control(type="date", v-model="to")
      .col-12.mt-2
        .input-group
          input.form-control.custom-control(type="text", :placeholder="this.$t('vouchers.list.searchPlaceholder')" v-model="filter" v-on:keyup.enter="filterVouchers")
          .input-group-append
            button.button.button-primary.button-tdays(@click="filterVouchers") {{$t("constants.buttons.search")}}
      .col-12.mt-2
        .float-right
          button.button.button-primary.button-tdays(@click="excelExport" id="excelExport")
            span Excel exportieren&nbsp;
            font-awesome-icon(:icon="['fas', 'download']")
          b-popover.rounded-0(target="excelExport" triggers="hover" placement="top") Export der Gutscheine anhand der eingestellten Tabellenfilter
      .col-12.mt-3
        .table-responsive
          table.table.table-bordered
            thead
              tr
                th(scope="col") {{$t("vouchers.list.table.voucherNo")}}
                th(scope="col") {{$t("vouchers.list.table.paidOn")}}
                th(scope="col") {{$t("vouchers.list.table.overnightStays")}}
                th(scope="col") {{$t("vouchers.list.table.guest")}}
                th(scope="col") {{$t("vouchers.list.table.voucherProceeds")}}
                th(scope="col") {{$t("vouchers.list.table.status")}}
                th(scope="col") {{$t("vouchers.list.table.arrivalOn")}}
                th(scope="col") {{$t("vouchers.list.table.notes")}}
                th(scope="col") {{$t("vouchers.list.table.details")}}
            tbody
              template(v-for="voucher in vouchers")
                VoucherTableColumn(
                  :voucher="voucher",
                )

      .col-12.mt-2
        new-pagination(:load-more="loadMoreVouchers" :overall-count="vouchersCount" :current-count="vouchers.length")

</template>

<script>
import EventBus from "../../../views/event-bus";
import { VoucherStatus } from "@/lib/graphql/voucherStatus/VoucherStatus.ts";
import VoucherTableColumn from "./partials/VoucherTableColumn";
import VoucherStatusEnum from "@/lib/enums/voucherStatus/VoucherStatusEnum";
import NewPagination from "../../partials/new-pagination.vue";
import { VouchersComponent } from "@/lib/components/vouchers/VouchersComponent";
import moment from "moment";

export default {
    name: "VouchersList",
    components: { NewPagination, VoucherTableColumn },
    props: {
        slug: String,
    },
    data() {
        return {
            vouchers: [],
            vouchersCount: 0,

            hostsVouchers: [],
            hostsVouchersCount: 0,
            voucherStatus: [],
            hostIds: [this.$session.get("hostId")],
            selectedVoucherStatus: [],
            selectedVoucherStatusIds: [
                VoucherStatusEnum.Created,
                VoucherStatusEnum.Sent,
                VoucherStatusEnum.Booked,
            ],

            offset: 0,
            limit: 15,
            from: undefined,
            to: undefined,
            filter: "",
            sort: "new",
        };
    },
    mounted() {
        EventBus.$on(
            "changeHostId",
            function () {
                this.hostIds = [this.$session.get("hostId")];
            }.bind(this)
        );
        EventBus.$on(
            "updateVouchersList",
            function () {
                this.vouchers = [];
                this.loadVouchers();
                this.loadVouchersCount();
            }.bind(this)
        );
        this.loadVouchers();
        this.loadVouchersCount();
    },
    methods: {
        filterVouchers() {
            try {
                if (this.from) {
                    if (!moment(this.from).isValid()) {
                        return this.$alert(
                            "Bitte wählen geben Sie gültiges 'von'-Datum aus."
                        );
                    }
                    if (moment(this.from).isValid()) {
                        return this.$alert(
                            "Bitte wählen geben Sie ein 'von'-Datum aus, welches später als 2000 ist."
                        );
                    }
                }
                if (this.to) {
                    if (!moment(this.to).isValid()) {
                        return this.$alert(
                            "Bitte wählen geben Sie gültiges 'bis'-Datum aus."
                        );
                    }
                    if (moment(this.to).year() < 2000) {
                        return this.$alert(
                            "Bitte wählen geben Sie ein 'bis'-Datum aus, welches später als 2000 ist."
                        );
                    }
                }
                this.vouchers = [];
                this.loadVouchers();
                this.loadVouchersCount();
            } catch (e) {
                console.log(e);
                this.$alert(e.message);
            }
        },
        loadMoreVouchers() {
            try {
                this.offset += this.limit;
                this.loadVouchers();
            } catch (e) {
                console.log(e);
                this.$alert(e.message);
            }
        },
        async loadVouchers() {
            try {
                const vouchersComponent = new VouchersComponent();
                EventBus.$emit("changeLoadingState", true);
                const vouchers = await vouchersComponent.getHostsVouchers(
                    this.$apollo,
                    this.hostIds,
                    this.filter,
                    this.selectedVoucherStatusIds,
                    this.from,
                    this.to,
                    this.offset,
                    this.limit,
                    this.sort
                );
                EventBus.$emit("changeLoadingState", false);
                if (!vouchers) {
                    return this.$alert(
                        "Es konnten keine Gutscheine geladen werden."
                    );
                }
                this.vouchers = this.vouchers.concat(vouchers);
            } catch (e) {
                console.log(e);
                this.$alert(e.message);
            }
        },
        async loadVouchersCount() {
            try {
                const vouchersComponent = new VouchersComponent();
                const vouchersCount =
                    await vouchersComponent.getHostsVouchersCount(
                        this.$apollo,
                        this.hostIds,
                        this.filter,
                        this.selectedVoucherStatusIds,
                        this.from,
                        this.to
                    );
                if (vouchersCount == undefined) {
                    return;
                }
                this.vouchersCount = vouchersCount;
            } catch (e) {
                console.log(e);
                this.$alert(e.message);
            }
        },
        changeVoucherStatus() {
            try {
                const selectedVoucherStatusIds = [];
                this.selectedVoucherStatus.forEach((s) =>
                    selectedVoucherStatusIds.push(parseInt(s.Id))
                );
                this.selectedVoucherStatusIds = selectedVoucherStatusIds;
            } catch (e) {
                console.log(e);
            }
        },
        async excelExport() {
            try {
                const confirmed = await this.$confirm(
                    "Sind Sie sicher, dass Sie die Gutscheine exportieren möchten, dies kann einige Zeit in Anspruch nehmen?"
                )
                    .then()
                    .catch((e) => e);
                if (!confirmed) {
                    return;
                }
                const vouchersComponent = new VouchersComponent();
                const hostsVouchersCount =
                    await vouchersComponent.getHostsVouchersCount(
                        this.$apollo,
                        this.hostIds,
                        this.filter,
                        this.selectedVoucherStatusIds,
                        this.from,
                        this.to
                    );
                if (hostsVouchersCount == undefined) {
                    return undefined;
                }
                let table;
                let offset = 0;
                let limit = 1;
                do {
                    EventBus.$emit("changeLoadingState", {
                        isLoading: true,
                        progress: `${offset} / ${hostsVouchersCount}`,
                    });
                    const hostsVouchersHtml =
                        await vouchersComponent.getHostVouchersTable(
                            this.$apollo,
                            this.hostIds,
                            this.filter,
                            this.selectedVoucherStatusIds,
                            this.from,
                            this.to,
                            offset,
                            limit,
                            this.sort
                        );
                    if (!hostsVouchersHtml) {
                        return undefined;
                    }
                    if (offset == 0) {
                        table =
                            vouchersComponent.createElementFromHTML(
                                hostsVouchersHtml
                            );
                    } else {
                        table.getElementsByTagName("tbody")[0].innerHTML +=
                            hostsVouchersHtml;
                    }
                    offset += limit;
                } while (offset + limit <= hostsVouchersCount);
                EventBus.$emit("changeLoadingState", false);
                const sa = window.open(
                    "data:application/vnd.ms-excel," +
                        encodeURIComponent(table.outerHTML)
                );
                return sa;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
    apollo: {
        voucherStatus: {
            query: VoucherStatus.Queries.VoucherStatus,
            loadingKey: "loading",
            result({ data }) {
                if (!data || !data.voucherStatus) {
                    return;
                }
                this.selectedVoucherStatus = data.voucherStatus.filter(
                    (s) =>
                        s.Id == VoucherStatusEnum.Created ||
                        s.Id == VoucherStatusEnum.Sent ||
                        s.Id == VoucherStatusEnum.Booked
                );
            },
        },
    },
    watch: {
        hostIds() {
            try {
                this.vouchers = [];
                this.loadVouchers();
                this.loadVouchersCount();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
};
</script>
